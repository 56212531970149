import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { setAnswer } from '../../actions/questionActions';
import es from 'date-fns/locale/es';
import { registerLocale } from 'react-datepicker';

registerLocale('es', es);
const Fecha = ({ title, idPregunta }) => {

    const dispatch = useDispatch();
    const answers = useSelector(state => state.question.answers);
    const answerValue = answers[idPregunta]?.answer || '';
    
    const handleDateChange = (event) => {
       
        
      dispatch(setAnswer(idPregunta, new Date(event)));
    };

    const selectedDate = typeof answerValue === 'string' && answerValue != "" ? new Date(answerValue) : answerValue;
   
    return (
        <div className="form-group mt-3" id='form-group-datapicker'>
            <label className='form-check-label' style={{paddingRight:'15px'}}> {title} </label>
            <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                showTimeSelect={false}
                dateFormat="yyyy-MM-dd"
                locale="es"
                className="custom-datepicker"
            />
        </div>

    );
};

export default Fecha;
