
import { notifySuccess, notifyError } from '../components/Toast';

export const loginUser = (credentials) => {
    return async (dispatch) => {
        dispatch({ type: 'USER_LOGIN_REQUEST' });

        let payload = {
            returnurl: "URL_DASHBOARD",
            username: credentials.username,
            password: credentials.password,
            appid: "microcredencial"
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_API_LOGIN_URL}`, {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Credenciales inválidas');
            }

            const user = await response.json();

            dispatch({ type: 'USER_LOGIN_SUCCESS', payload: user });
        } catch (error) {
            dispatch({ type: 'USER_LOGIN_FAIL', payload: error.message });
        }
    };
};

export const loginExternalUser = (credentials) => {
    return async (dispatch) => {
        dispatch({ type: 'USER_LOGIN_REQUEST' });

        let payload = {            
            mail: credentials.username,
            password: credentials.password,
            appid: "microcredencial"
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/microcredenciales/usuarios/login`, {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Credenciales inválidas');
            }

            const user = await response.json();

            dispatch({ type: 'USER_LOGIN_SUCCESS', payload: user });
        } catch (error) {
            dispatch({ type: 'USER_LOGIN_FAIL', payload: error.message });
        }
    };
};

export const logoutUser = () => ({
    type: 'USER_LOGOUT',
});

export const createExternalUser = (userData, user, proposalId) => async (dispatch) => {
    try {
        dispatch({ type: 'CREATE_EXTERNAL_USER' });
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}microcredenciales/usuarios/externo/save?propuesta=${proposalId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user.token
            },
            body: JSON.stringify(userData),
        });

        if (response.ok) {
            //const proposalId = await response.json();
            // requestData.Id = proposalId;
            dispatch({ type: 'CREATE_EXTERNAL_USER_SUCCESS' });
            notifySuccess('Usuario externo agregado exitosamente!')
            //navigate('/backoffice/');
        } else {
            const errorData = await response.statusText;
            notifyError('Error al agregar usuario externo');
            dispatch({ type: 'CREATE_EXTERNAL_USER_FAIL', payload: errorData });
        }
    } catch (error) {
        notifyError('Error al agregar usuario externo');
        dispatch({ type: 'CREATE_EXTERNAL_USER_ERROR', payload: error.message });
    }
};

