import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMicrocredentialTypes } from '../actions/typeActions';
import { createRequest } from '../actions/requestActions';
import arrowRightImage from '../assets/arrow-small-right.png';
import { useNavigate } from 'react-router-dom';
import AttachmentButton from './AttachmentButton';
import {RequestStatus} from '../enums/requestStatus';
import mcProceso from '../assets/mcProceso.png';

const RequestForm = () => {   
    const [projectName, setProjectName] = useState('');
    const [selectedMicrocredential, setSelectedMicrocredential] = useState('');
    const [recipientsDescription, setProjectRecipients] = useState('');
    const [learningResults, setLearningResults] = useState('');
    const [contentResults, setContentResults] = useState('');
    const [projectRelevance, setProjectRelevance] = useState('');
    const [grantCredits, setGrantCredits] = useState(false);
    const [attachment, setAttachment] = useState(null);
    const dispatch = useDispatch();
    const { microcredentials, loading, error } = useSelector((state) => state.types);
    const { userInfo } = useSelector(state => state.user);

    const navigate = useNavigate();

    const handleBack = () => {
        navigate('/backoffice'); 
    };

    useEffect(() => {
        dispatch(fetchMicrocredentialTypes(userInfo));
    }, [dispatch]);

    const handleFileSelect  = (file) => {
        setAttachment(file); // Get the selected file
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const requestData = {
            resultadosContenido: contentResults, 
            resultadosAprendizaje: learningResults,
            pertinencia: projectRelevance, 
            usuario: {
                EntityId: userInfo.userid
            },
            microcredencial: {
                Id: selectedMicrocredential
            },
            
            destinatarios: recipientsDescription,
            estado:{
                Id: RequestStatus.Pendiente.id
            },
            nombre: projectName,
            otorgaCreditos: grantCredits
        };
        dispatch(createRequest(requestData, attachment, userInfo, navigate, false));
    };

    return (
        <div style={{marginBottom:'20px'}}>
            <section className="container1">                
                <a className="btn_back" onClick={handleBack}>
                    Volver al home
                </a>
                <section>
                    <div className="bienvenida2">
                        <div className="first_col1">
                            <h2>¿Estás listo para lanzarte al mundo de las microcredenciales?</h2>
                            <p>Para dar inicio al proceso, completa esta solicitud de preautorización.</p>
                            <div className="alcance_content">
                                <img src={arrowRightImage} alt="" />
                                <p>Te responderemos en un plazo máximo de 15 días hábiles.</p>
                            </div>
                            <div className=''>
                                <img src={mcProceso} style={{ width: '90%' }} alt="" />
                            </div>
                        </div>
                        <div className="second_col1">
                            <div className="frame-parent">
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group className="tipo-de-credencial-parent mb-3" controlId="projectName">
                                        <Form.Label className='nombre'>Nombre del proyecto</Form.Label>
                                        <Form.Control type="text" maxLength="99" placeholder="Escriba el nombre del proyecto" required value={projectName} onChange={(e) => setProjectName(e.target.value)} />
                                    </Form.Group>                                   
                                    <Form.Group className="tipo-de-credencial-parent mb-3" controlId="selectedMicrocredential">
                                        <Form.Label className='nombre'>Tipo de microcredencial</Form.Label>
                                        <Form.Select value={selectedMicrocredential} onChange={(e) => setSelectedMicrocredential(e.target.value)} required>
                                            <option value="">Selecciona el tipo de microcredencial</option>
                                            {microcredentials.map((microcredential) => (
                                                <option key={microcredential.Id} value={microcredential.Id}>{microcredential.tipoNombre}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group className="tipo-de-credencial-parent mb-3" controlId="learningResults">
                                        <Form.Label className='nombre'>Describa los Resultados de Aprendizaje que espera que logren los participantes</Form.Label>
                                        <Form.Control type="text" placeholder="Describa los Resultados de Aprendizaje que espera que logren los participantes" required value={learningResults} onChange={(e) => setLearningResults(e.target.value)} />
                                    </Form.Group>
                                    <Form.Group className="tipo-de-credencial-parent mb-3" controlId="projectName">
                                        <Form.Label className='nombre'>Realice y entregue un mapeo (diagrama/detalle) de los contenidos en relación con los Resultados de aprendizaje</Form.Label>
                                        <Form.Control type="text" placeholder="Describa los contenidos en relación con los Resultados de aprendizaje" required value={contentResults} onChange={(e) => setContentResults(e.target.value)} />
                                        {/* <Form.Label className='nombre'>Adjuntar documento</Form.Label>
                                        <Form.Control type="file" accept=".pdf,.jpg,.png" onChange={handleAttachmentChange} /> */}
                                    </Form.Group> 
                                    <AttachmentButton onFileSelect={handleFileSelect}  />                                                                 
                                    <Form.Group className="tipo-de-credencial-parent mb-3" controlId="projectName">
                                        <Form.Label className='nombre'>Explicite quienes son los destinatarios del proyecto de microcredencial</Form.Label>
                                        <Form.Control type="text" placeholder="Describa los destinatarios del proyecto de microcredencial" required value={recipientsDescription} onChange={(e) => setProjectRecipients(e.target.value)} />
                                    </Form.Group>                                    
                                    <Form.Group className="tipo-de-credencial-parent mb-3" controlId="projectDescription">
                                        <Form.Label className='nombre'>Explicite la pertinencia del proyecto en términos de las demandas sociales/profesionales a las que atiende</Form.Label>
                                        <Form.Control as="textarea" rows={3} placeholder="Describa la pertinencia del proyecto en términos de las demandas sociales/profesionales a las que atiende" required value={projectRelevance} onChange={(e) => setProjectRelevance(e.target.value)} />
                                    </Form.Group>
                                    <Form.Group className="tipo-de-credencial-parent mb-3" controlId='projectCreditsNo'>
                                        <Form.Label className='nombre' >Indique si la microcredencial otorgara creditos academicos</Form.Label>
                                        <Form.Check label="Si" value={true} name="creditos" type='radio' onChange={ (r) => {setGrantCredits(r.target.value)}}  />
                                        <Form.Check label="No" value={false} name="creditos" type='radio'  onChange={ (r) => {setGrantCredits(r.target.value)}}  />
                                    </Form.Group>
                                    <Button variant="primary" type="submit" disabled={loading}>Enviar solicitud</Button>
                                </Form>
                                {loading && <p>Cargando...</p>}
                                {error && <p>Error: {error}</p>}
                            </div>
                        </div>
                    </div>
                </section>
            </section>

        </div>
    );
};

export default RequestForm;
