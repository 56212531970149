import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAnswer } from '../../actions/questionActions';
import { Form } from 'react-bootstrap';

const RadioTexto = ({ title, options, idPregunta, observacion }) => {

    const dispatch = useDispatch();
    const answers = useSelector(state => state.question.answers);
    const answerValue = answers[idPregunta]?.answer || '';
    const observacionValue = answers[idPregunta]?.observacion || '';

    const handleChange = (event) => {        
        dispatch(setAnswer(idPregunta, event.target.value, observacionValue));
    };
    const handleObservacion = (textValue) => {
        dispatch(setAnswer(idPregunta, answerValue, textValue));
    };
    return (
        <div className="form-check">
            <label className='question-title'>{title}</label>
            <div style={{marginTop:'2%',marginBottom:'4%'}}>
            {options.map(option => (
                <div key={option.Id}>
                    <input
                        type="radio"
                        id={option.Id}
                        className='form-check-input'
                        name={title}
                        value={option.Id}
                        checked={answerValue == option.Id}
                        onChange={handleChange}
                    />
                    <label style={{marginBottom:'2%'}} className="form-check-label" htmlFor={option.Id}>{option.titulo}</label>
                </div>
            ))}
            </div>
            {observacion &&                
                <Form.Group className="tipo-de-credencial-parent mb-3">
                    <Form.Label className='nombre'>Observaciones:</Form.Label>
                    <Form.Control as="textarea" rows={3} placeholder="Ingresa tu respuesta" required value={observacionValue} onChange={(e) => handleObservacion(e.target.value)} />
                </Form.Group>
            }
                
        </div>
    );
};

export default RadioTexto;
