// requestReducer.js
const initialState = {
    requests: [],
    loading: false,
    error: null,
};

const requestReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CREATE_REQUEST':
            return {
                ...state,
                loading: true,
                error: null,
            };
        case 'CREATE_REQUEST_SUCCESS':
            return {
                ...state,
                //requests: [...state.requests, action.payload],
                loading: false,
                error: null,
            };
        case 'CREATE_REQUEST_FAIL':
            return {
                ...state,
                //requests: [...state.requests, action.payload],
                loading: false,
                error: action.payload,
            };
        case 'CREATE_REQUEST_ERROR':
            return {
                ...state,
                //requests: [...state.requests, action.payload],
                loading: false,
                error: action.payload,
            };
        case 'GET_ALL_REQUESTS':
            return {
                ...state,
                loading: true,
                error: null,
            };
        case 'GET_ALL_REQUESTS_SUCCESS':
            return {
                ...state,
                requests: action.payload,
                loading: false,
                error: null,
            };
        case 'GET_ALL_REQUESTS_ERROR':
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case 'GET_FILE_REQUEST':
            return {
                ...state,
                loading: true,
                error: null,
            };
        case 'GET_FILE_REQUEST_SUCCESS':
            return {
                ...state,
                loading: false,
                error: null,
            };
        case 'GET_FILE_REQUEST_ERROR':
        case 'GET_FILE_REQUEST_FAIL':
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case 'UPDATE_REQUEST':
            return {
                ...state,
                requests: state.requests.map(request =>
                    request.id === action.payload.requestId ? { ...request, ...action.payload.updatedData } : request
                ),
            };
        case 'DELETE_REQUEST':
            return {
                ...state,
                requests: state.requests.filter(request => request.id !== action.payload),
            };
        default:
            return state;
    }
};

export default requestReducer;
