import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';

const AttachmentButton = ({ onFileSelect }) => {
    const [fileName, setFileName] = useState('');
    const handleFileSelect = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFileName(file.name); 
            onFileSelect(file);
        }
    };
    return (
        <div>
            <input
                type="file"
                id="file-input"
                style={{ display: 'none' }}
                onChange={handleFileSelect}
            />
            <label htmlFor="file-input" className="attachment-button mb-3">
                <FontAwesomeIcon icon={faPaperclip} className="attachment-icon" />
                {fileName || "Adjuntar archivo"}
            </label>
        </div>
    );
};
export default AttachmentButton;
