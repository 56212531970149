import React, { useState, useEffect } from 'react';
import QuestionComponentFactory from './QuestionComponentFactory';
import QuestionCard from './question/QuestionCard';
import { useDispatch, useSelector } from 'react-redux';
import { getAllQuestions, saveAnswer, getAllAnswers } from '../actions/questionActions';
import { useParams, useNavigate } from 'react-router-dom';
import { notifyError, notifySuccess } from './Toast';
import { setJuryEvaluation } from '../actions/proposalActions';

const QuestionnaireJury = () => {
    const { proposalId } = useParams();
    const dispatch = useDispatch();
    const { userInfo } = useSelector(state => state.user);
    const { questions, answers, loading, error } = useSelector(state => state.question);
    const [currentStep, setCurrentStep] = useState(0);   
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const stageId = '9E9BEC89-5462-47A5-A5A8-61CCBE63E66D';// Evaluacion  
            dispatch(getAllQuestions(userInfo, stageId))
            dispatch(getAllAnswers(userInfo, proposalId));
        };

        fetchData();
    }, [dispatch, userInfo]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        saveCurrentAnswer();
        
        let errorList = [];

        questions.forEach(q => {
            const currentQuestions = q.preguntas;

            currentQuestions.forEach(element => {

                const currentAnswer = answers[element.Id];

                if (currentAnswer == undefined) {

                    if (element.subpreguntas.length == 0) {
                        errorList.push(questions.indexOf(q) + 1);
                    }
                    else {
                        element.subpreguntas.forEach(sub => {
                            let currentSubAnswer = answers[sub.Id];
                            if (currentSubAnswer == undefined) {
                                errorList.push(questions.indexOf(q) + 1);
                            } else {
                                if (currentSubAnswer.answer == "") {
                                    errorList.push(questions.indexOf(q) + 1);
                                }
                            }
                        })
                    }

                } else {                    
                    if (currentAnswer.answer == "") {
                        errorList.push(questions.indexOf(q) + 1);
                    }
                }
            });

        });
       
        if (errorList.length > 0) {
            let errorString = "Evaluación incompleta. Faltan completar las siguientes preguntas: ";
            errorList = [...new Set(errorList)]; 
            errorString += errorList.join(", ");

            notifyError(errorString);
            setCurrentStep(prev => prev - 1);
            return;

        } else {
            setCurrentStep(prev => prev - 1);
            dispatch(setJuryEvaluation(proposalId, userInfo, navigate));
        }

    };


    const progress = (currentStep / (questions.length - 1)) * 100; // Calculate progress

    const saveCurrentAnswer = () => {

        const currentSepQuestion = questions[currentStep];

        const currentQuestions = currentSepQuestion.preguntas;

        currentQuestions.forEach(element => {

            const currentAnswer = answers[element.Id];

            if (currentAnswer == undefined) {
                element.subpreguntas.forEach(sub => {
                    let currentSubAnswer = answers[sub.Id];
                    if (currentSubAnswer != undefined) {
                        save(sub, currentSubAnswer);
                    }
                })
            } else {
                save(element, currentAnswer);
            }
        });

        setCurrentStep(prev => prev + 1);
    }


    const save = (question, currentAnswer) => {
        let file = null;
        let rta = currentAnswer.answer;
        if (question.tipo == "Archivo") {
            file = currentAnswer.answer;
            rta = "archivo";
        }
        //console.log("currentAnswer", currentAnswer);
        let answerData = {
            propuesta: {
                Id: proposalId
            },
            preguntaAsociada: {
                Id: question.Id
            },
            opcionElegida: {
            },
            respuesta: rta,
            observacion: currentAnswer.observacion,
            tieneArchivo: (question.tipo == "Archivo"),
            rutaArchivo: question.tipo == "Archivo" ? currentAnswer.filePath : "",
            nombreArchivo: question.tipo == "Archivo" ? currentAnswer.fileName : "",
            esDicatamen: false
        };
        if (currentAnswer.answerId !== undefined) {
            answerData.Id = currentAnswer.answerId;
        }
        
        dispatch(saveAnswer(answerData, userInfo, file));

    }
    return loading ? (<div>Cargando... </div>) : (
        <form onSubmit={handleSubmit}>
            {questions.length > 0 && (
                <div className='col-12 container-question' >
                    <div className='row'>                        
                        <div className='col-8 container-question-factory'>
                            <div className='row'>
                                <div className='col-8'>
                                    {/* Progress bar */}
                                    {<span className='paging'>Pagina {currentStep + 1} de {questions.length}</span>}

                                    <div className="progress">
                                        <div
                                            className="progress-bar"
                                            role="progressbar"
                                            style={{ width: `${progress}%` }}
                                            aria-valuenow={progress}
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                        ></div>
                                    </div>
                                </div>
                                <div className='col-4'>
                                    {currentStep > 0 && <button type="button" className='btn-question' onClick={() => setCurrentStep(prev => prev - 1)}>Anterior</button>}
                                    {currentStep < questions.length - 1 && <button type="button" className='btn-question' onClick={() => saveCurrentAnswer()}>Siguiente</button>}
                                    {currentStep === questions.length - 1 && <button type="submit" className='btn-question-fin' onClick={(ev) => handleSubmit(ev)}>Finalizar</button>}
                                </div>
                            </div>
                            <div className='row mt-4'>
                                <QuestionComponentFactory question={questions[currentStep]} currentStep={currentStep + 1} />
                            </div>
                        </div>
                        <div className='col-4'>
                            { <QuestionCard questions={questions} currentStep={currentStep} /> }
                        </div>
                    </div>
                </div>
            )}
        </form>
    );
};

export default QuestionnaireJury;
