import React, { useState, useCallback } from 'react';
import { Navbar, Nav, Button, Dropdown } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import logoBlanco from '../assets/logo_blanco.png'; // Import your logo image
import { logoutUser } from '../actions/userActions'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

const NavigationBarBackoffice = () => {
    const { userInfo } = useSelector(state => state.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const handleLogout = () => {
        // Dispatch the logout action
        dispatch(logoutUser());
        // Navigate to the home page or any other desired page after logout
        navigate('/');
    };
    return (
        <>
        <header className="home_nav">
            <Navbar expand="lg" style={{width: '100%'}}>
                <Navbar.Brand as={Link} to="/backoffice">
                    <div className="logo">
                        <img src={logoBlanco} alt="UAI microcredenciales" />
                    </div>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">                  
                        <Nav>
                           
                            <Dropdown>
                                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                    <FontAwesomeIcon icon={faUser} /> Cuenta
                                </Dropdown.Toggle>
                                <Dropdown.Menu>                                    
                                    <Dropdown.Item as={Link} to="/backoffice/account">Cuenta</Dropdown.Item>
                                    <Dropdown.Item onClick={handleLogout}><FontAwesomeIcon icon={faSignOutAlt} /> Cerrar sesión</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Nav>                    
                </Navbar.Collapse>
            </Navbar>    
            </header>        
        </>
    );
};

export default NavigationBarBackoffice;
