import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getAllJuryAnswers } from '../actions/questionActions';
import { updateProposal } from '../actions/proposalActions';
import { ProposalStatus } from '../enums/proposalStatus';

const ProposalDetail = () => {
    const { proposalId } = useParams();
    const { proposals } = useSelector(state => state.proposal);
    const { juryAnswers } = useSelector(state => state.question);
    const [proposal, setProposal] = useState(null);
    const [observacion, setObservacion] = useState('');
    const dispatch = useDispatch();    
    const { userInfo } = useSelector(state => state.user);
    const [valueDictamen, setValueDictamen] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const foundProposal = proposals.find(x => x.Id === proposalId);
        setProposal(foundProposal);
        dispatch(getAllJuryAnswers(userInfo, proposalId));       
    }, [proposalId, proposals]);

    const handleBack = () => {
        navigate('/backoffice');
    };
   
    const handleSubmit = async (e) => {
        e.preventDefault();
        const proposalData = {
            ...proposal,
            observaciones: observacion,
            estado: {
                Id: ProposalStatus.AceptadaConObservaciones.id
            }
        };
        //console.log("proposalData", proposalData);
        dispatch(updateProposal(proposalData, userInfo, navigate));
    };
    const [openAccordion, setOpenAccordion] = useState(0);

    const handleAccordionToggle = (index) => {
        setOpenAccordion(index === openAccordion ? null : index);
    };
    return (
        <div style={{ marginBottom: '20px' }}>
            <section className="container1">
            <div className='row mb-5'>
                    <div className='col-4'>
                    <a className="btn_back" onClick={handleBack}>
                        Volver al home
                    </a>
                    </div>
                   
                </div>
            <div style={{ marginBottom: '20px' }}>
                <section className="container-question">

                    <section className='mt-5'>
                        {proposal && (
                            <>
                                <div>
                                    <div className='row mb-5'>
                                        <h2>Detalle de Evaluación de Microcredencial {proposal.nombre} </h2>
                                        <p className='revisar'>Revisa el detalle de la comparativa de la evaluación para poder tomar una acción correspondiente.</p>
                                    </div>
                                    <form onSubmit={handleSubmit} className='col-12'>
                                        <div className='row'>
                                            <div className='col-8' >
                                                {juryAnswers.length > 0 ? (
                                                    <div className="accordion">
                                                        {juryAnswers.map((item, index) => (
                                                            <div className="card" key={index}>
                                                                <div className="card-header" onClick={() => handleAccordionToggle(index)}>
                                                                    <div className="accordion-content">
                                                                        <span className="accordion-text">{item.titulo}</span>
                                                                        <span className={`accordion-icon ${openAccordion === index ? 'open' : ''}`}></span>
                                                                    </div>
                                                                </div>
                                                                <div id={`accordion-${index}`} className={`collapse ${openAccordion === index ? 'show' : ''}`}>
                                                                    <div className="card-body">
                                                                        {item.preguntas.map((ev) => (
                                                                            <div key={ev.Id}>
                                                                                <p className='eval-title mb-3'>{ev.orden}. {ev.titulo}</p>
                                                                                
                                                                                {ev.jueces.filter(x => x.usuarioExterno == null).map((juez) => (
                                                                                    <div key={juez.Id} style={{marginLeft: '15px'}}>
                                                                                        <p className='eval-title '>{juez.idUsuario}</p>
                                                                                        <p className='eval-rta mb-3'>{juez.Respuesta.respuesta === "1" ? "Sí" : "No"}</p>
                                                                                    </div>
                                                                                ))}   
                                                                                 {ev.jueces.filter(x => x.usuarioExterno != null).map((juez) => (
                                                                                    <div key={juez.Id} style={{marginLeft: '15px'}}>
                                                                                        <p className='eval-title '>{juez.usuarioExterno.nombre} {juez.usuarioExterno.apellido}</p>
                                                                                        <p className='eval-rta mb-3'>{juez.Respuesta.respuesta === "1" ? "Sí" : "No"}</p>
                                                                                    </div>
                                                                                ))}                                                                                
                                                                            </div>
                                                                        ))}


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>

                                                ) : null}
                                            </div>
                                            <div className='col-4'>
                                                <Card className='card-question'>
                                                    <Card.Title className='card-title'>
                                                        Dictamen final del evaluador
                                                    </Card.Title>
                                                    <Card.Body>
                                                        <Card.Text>

                                                            <div className='card-body-detail'>
                                                                <p className='mb-3'>Del análisis de consistencia alcanzado a partir de lo valorado por cada evaluador, la propuesta resulta:</p>
                                                                
                                                                <div className='form-group mb-3'>
                                                                    <p className='detail-title'>Observaciones</p>
                                                                    <Form.Control as="textarea" rows={3} placeholder="Ingresa tu respuesta" value={observacion} onChange={(e) => setObservacion(e.target.value)} />
                                                                </div>
                                                                <div className='form-group mb-3' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                    <button className='btnFinalizarDictamen' onClick={(ev) => handleSubmit(ev)}>Finalizar dictamen</button>
                                                                </div>
                                                            </div>

                                                        </Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </>
                        )}
                    </section>
                </section>
            </div>
            </section>
        </div>
    );
};

export default ProposalDetail;
