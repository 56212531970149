// requestReducer.js
const initialState = {
    microcredentials: [],
    loading: false,
    error: null,
};

const typeReducer = (state = initialState, action) => {
    switch (action.type) {       
            case 'FETCH_MICROCREDENTIAL_TYPES_REQUEST':
                return {
                    ...state,
                    loading: true,
                    error: null,
                };
            case 'FETCH_MICROCREDENTIAL_TYPES_SUCCESS':
                return {
                    ...state,
                    microcredentials: action.payload,
                    loading: false,
                    error: null,
                };
            case 'FETCH_MICROCREDENTIAL_TYPES_ERROR':
                return {
                    ...state,
                    loading: false,
                    error: action.payload,
                };
        
        default:
            return state;
    }
};

export default typeReducer;
