import React, { useState, useEffect } from 'react';
import QuestionComponentFactory from './QuestionComponentFactory';
//import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import QuestionCard from './question/QuestionCard';
import { useDispatch, useSelector } from 'react-redux';
import { getAllQuestions, saveAnswer, getAllAnswers } from '../actions/questionActions';
import { useParams, useNavigate } from 'react-router-dom';
import { ProposalStatus} from '../enums/proposalStatus';
import { updateProposal } from '../actions/proposalActions';
const Questionnaire = () => {
    const { proposalId } = useParams();
    const dispatch = useDispatch();
    const { userInfo } = useSelector(state => state.user);
    const { questions, answers, loading, error } = useSelector(state => state.question);
    const [currentStep, setCurrentStep] = useState(0);
    const navigate = useNavigate();
    
    useEffect(() => {
        const fetchData = async () => {
            const stageId = 'F8DCD0C7-E379-4EE9-B5C5-280C1604B604';
            
            dispatch(getAllQuestions(userInfo, stageId));
            dispatch(getAllAnswers(userInfo, proposalId));
        };

        fetchData();
    }, [dispatch, userInfo]);
   
    const handleSubmit = async (e) => {
        e.preventDefault();        

        saveCurrentAnswer();

        const proposalData = {
            Id: proposalId,            
            estado: {
                Id: ProposalStatus.Pendiente.id
            },
            solicitud:{
                Id: ""
            }
        };

        dispatch(updateProposal(proposalData, userInfo, navigate));
    };
    const progress = (currentStep / (questions.length - 1)) * 100; // Calculate progress
    //console.log(questions[currentStep]?.preguntas);
    const saveCurrentAnswer = () => {

        const currentSepQuestion = questions[currentStep];

        const currentQuestions = currentSepQuestion.preguntas;

        currentQuestions.forEach(element => {
            
            const currentAnswer = answers[element.Id];
            
            /*if (currentAnswer === undefined) {
                element.subpreguntas.forEach(sub => {
                    let currentSubAnswer = answers[sub.Id];
                    if (currentSubAnswer !== undefined) {
                        save(sub, currentSubAnswer);
                    }
                })
            } else {
                save(element, currentAnswer);
                element.subpreguntas.forEach(sub => {
                    let currentSubAnswer = answers[sub.Id];

                    if (currentSubAnswer.multipleAnswers && Array.isArray(currentSubAnswer.multipleAnswers)) {
                        currentSubAnswer.multipleAnswers.forEach(answ => {
                            save(sub, answ);
                        });
                    } else {
                        save(sub, currentSubAnswer);
                    }
                })
            }*/

            if (currentAnswer === undefined) {
                element.subpreguntas.forEach(sub => {
                    let currentSubAnswer = answers[sub.Id];
                    
                    if (currentSubAnswer !== undefined) {
                        
                        if (currentSubAnswer.multipleAnswers && Array.isArray(currentSubAnswer.multipleAnswers)) {
                            currentSubAnswer.multipleAnswers.forEach(answ => {
                                save(sub, answ);
                            });
                        } else {
                            save(sub, currentSubAnswer);
                        }
                    }
                });
            } else {
                save(element, currentAnswer);

                element.subpreguntas.forEach(sub => {
                    let currentSubAnswer = answers[sub.Id];
                    
                    if (currentSubAnswer !== undefined) {
                        
                        if (currentSubAnswer.multipleAnswers && Array.isArray(currentSubAnswer.multipleAnswers)) {
                            currentSubAnswer.multipleAnswers.forEach(answ => {
                                save(sub, answ);
                            });
                        } else {
                            save(sub, currentSubAnswer);
                        }
                    }
                });
            }
        });

        setCurrentStep(prev => prev + 1);
    }

    
    const save = (question, currentAnswer) => {       
        let file = null;
        let rta = currentAnswer.answer;
        if (question.tipo == "Archivo") {
            file = currentAnswer.answer;
            rta = "archivo";
        }

        let answerData = {
            propuesta: {
                Id: proposalId
            },
            preguntaAsociada: {
                Id: question.Id
            },
            opcionElegida: {
            },
            respuesta: rta,
            observacion: currentAnswer.observacion,
            tieneArchivo: (question.tipo == "Archivo"),
            rutaArchivo: question.tipo == "Archivo" ? currentAnswer.filePath : "",
            nombreArchivo: question.tipo == "Archivo" ? currentAnswer.fileName : "",
            esDicatamen: false
        };
        if (currentAnswer.answerId !== undefined) {
            answerData.Id = currentAnswer.answerId;
        }
        if(currentAnswer.multipleAnswers && Object.keys(currentAnswer.multipleAnswers).length > 0){
            answerData.respuestasMultiples = Object.entries(currentAnswer.multipleAnswers).map(([key, value]) => {
                return {
                    Id: value.answerId !== undefined ? value.answerId : undefined,
                    pregunta: { 
                        Id: key
                    },
                    respuesta: value.answer 
                };
            });
        }

        dispatch(saveAnswer(answerData, userInfo, file)).then(() => {
            if (currentAnswer.answerId === undefined) {
                
                dispatch(getAllAnswers(userInfo, proposalId));
            }
        });
        
    }
    //console.log('Respuestas  ',answers)
    return (
        <form onSubmit={handleSubmit}>
            {questions.length > 0 && (
                <div className='col-12 container-question' >
                    <div className='row'>
                        <div className='col-8 container-question-factory'>
                            <div className='row'>
                                <div className='col-8'>
                                    {/* Progress bar */}
                                    <span className='paging'>Pagina {currentStep + 1} de {questions.length}</span>
                                    <div className="progress">
                                        <div
                                            className="progress-bar"
                                            role="progressbar"
                                            style={{ width: `${progress}%` }}
                                            aria-valuenow={progress}
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                        ></div>
                                    </div>
                                </div>
                                <div className='col-4'>
                                    {currentStep > 0 && <button type="button" className='btn-question' onClick={() => setCurrentStep(prev => prev - 1)}>Anterior</button>}
                                    {currentStep < questions.length - 1 && <button type="button" className='btn-question' onClick={() => saveCurrentAnswer()}>Siguiente</button>}
                                    {currentStep === questions.length - 1 && <button type="submit" className='btn-question-fin' onClick={(e) => handleSubmit(e)}>Finalizar</button>}
                                </div>
                            </div>
                            <div className='row mt-4'>
                                {<QuestionComponentFactory question={questions[currentStep]} currentStep={currentStep + 1} />}
                            </div>
                        </div>
                        <div className='col-4'>
                            <QuestionCard questions={questions} currentStep={currentStep} />
                        </div>
                    </div>
                </div>
            )}
        </form>
    );
};

export default Questionnaire;
