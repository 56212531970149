import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAnswer } from '../../actions/questionActions';
import { Form, Button } from 'react-bootstrap';

const YesNo = ({ title, idPregunta, observacion }) => {
    const dispatch = useDispatch();

    const answers = useSelector(state => state.question.answers);
    const answerValue = answers[idPregunta]?.answer || '';
    const observacionValue = answers[idPregunta]?.observacion || '';

    const handleChange = (event) => {
        const newValue = event.target.value;
        //setRadioValue(newValue);
        dispatch(setAnswer(idPregunta, newValue, observacionValue));
    };

    const handleObservacion = (textValue) => {
        dispatch(setAnswer(idPregunta, answerValue, textValue));
    };
    
    return (

        <div className="form-check">
            <label className='question-subtitle'>{title}</label>
            <div style={{marginLeft:'5%',marginTop:'2%',marginBottom:'6%'}}>
            <div>
                <input
                    type="radio"
                    id={`si${idPregunta}`}
                    className='form-check-input'
                    name={`tituloSi${idPregunta}`}
                    value="1"
                    checked={answerValue == "1"}
                    onChange={handleChange}
                />
                <label style={{marginBottom:'1%'}} className="form-check-label" htmlFor={`si${idPregunta}`}>Si</label>
            </div>
            <div>
                <input
                    type="radio"
                    id={`no${idPregunta}`}
                    className='form-check-input'
                    name={`tituloNo${idPregunta}`}
                    value="0"
                    checked={answerValue == "0"}
                    onChange={handleChange}
                />
                <label style={{marginBottom:'1%'}} className="form-check-label" htmlFor={`no${idPregunta}`}>No</label>
            </div>
            </div>
            
            {observacion &&                
                <Form.Group className="tipo-de-credencial-parent mb-3">
                    <Form.Label className='nombre'>Observaciones:</Form.Label>
                    <Form.Control as="textarea" rows={3} placeholder="Ingresa tu respuesta" required value={observacionValue} onChange={(e) => handleObservacion(e.target.value)} />
                </Form.Group>
            }
                
        </div>
    );
};

export default YesNo;