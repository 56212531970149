
import React from 'react';
import Texto from './question/Texto';
import RadioTexto from './question/RadioTexto';
import TextoArchivo from './question/TextoArchivo';
import YesPreguntaNo from './question/YesPreguntaNo';
import Archivo from './question/Archivo';
import Padre from './question/Padre';
import YesNo from './question/YesNo';
import ReconocimientoCreditos from './question/ReconocimientoCreditos';

const QuestionComponentsFactory = ({ question, currentStep }) => {
    
    const renderQuestion = (q) => {       
        return (
            <div>
                <label className='question-title'>{currentStep}. {q.titulo}</label>

                {q.preguntas && q.preguntas.map((pregunta, index) => {
                    if(pregunta.Id === '1bdcd6f4-4c5c-4c41-8bf7-718fafe4d7ff'){
                        return (
                            <ReconocimientoCreditos
                                key={index}
                                title={pregunta.titulo} 
                                additionalQuestions={pregunta.subpreguntas}
                                idPregunta={pregunta.Id}
                            />

                        );
                    }else{
                        switch (pregunta.tipo) {
                            case 'Texto':
                                return (
                                    <Texto
                                        key={index}
                                        title={pregunta.titulo}
                                        idPregunta={pregunta.Id}
                                    />
                                );
                            case 'Archivo':
                                return (
                                    <Archivo
                                        key={index}
                                        title={pregunta.titulo}
                                        idPregunta={pregunta.Id}
                                    />
                                );
                            case 'Padre':
                                return (<div className='row'><Padre key={index}
                                    title={pregunta.titulo}
                                    additionalQuestions={pregunta.subpreguntas} /></div>);
                            case 'YesPregunta/No':
                            case 'YesTexto/No':
                                return <YesPreguntaNo key={index}
                                    title={pregunta.titulo} additionalQuestions={pregunta.subpreguntas}
                                    idPregunta={pregunta.Id}
                                />;
                            case 'RadioTexto':
                            case 'Radio':
                                return <RadioTexto key={index} title={pregunta.titulo} options={pregunta.subpreguntas} idPregunta={pregunta.Id} observacion={pregunta.tiene_observacion}  />;
                            case 'YesNo':
                                return <YesNo key={index} title={pregunta.titulo} options={pregunta.subpreguntas} idPregunta={pregunta.Id} observacion={pregunta.tiene_observacion} />;
                            default:
                                return null;
                        }
                    }
                })}
            </div>
        );
    };

    if(question != undefined)
        return renderQuestion(question);
    
};

export default QuestionComponentsFactory;
