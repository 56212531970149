import React, { useState, useEffect, useMemo } from 'react';
import Texto from './Texto';
import Select from 'react-select';
import SelectAnimated from 'react-select/animated';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPlanes, setAnswer, setAnswerMultiple,deleteAnswer } from '../../actions/questionActions';
import Accordion from 'react-bootstrap/Accordion';
import { Form} from 'react-bootstrap';

const ReconocimientoCreditos = ({ title, additionalQuestions, idPregunta }) => {

    const dispatch = useDispatch();
    const answers = useSelector(state => state.question.answers);
    const answerValue = answers[idPregunta]?.answer || '';
    const makeAnimated = SelectAnimated();
    const { userInfo } = useSelector(state => state.user);
    
    const answerCareer = useSelector(state => state.question.answers['5f4e7417-e023-4da1-8c00-ee67e0ebb8cd']);
    const [careerSelected,setCareerSelected] = useState(null);
    const careerOptions = useMemo(() => //Obtengo las opciones de carreras
        additionalQuestions.find(q => q.Id === '5f4e7417-e023-4da1-8c00-ee67e0ebb8cd')?.opciones.map(op => ({
            value: op.EntityId,
            label: op.descripcion
        })), [additionalQuestions]
    );

    const answerPlan = useSelector(state => state.question.answers['68b4a35f-3882-477d-97f9-f528057d1f10']);
    const [planSelected,setPlanSelected] = useState();
    const [isPlanDisabled, setIsPlanDisabled] = useState(true);
    const planOptions = useSelector(state => state.question.plans)?.map(plan => ({
        value: plan.EntityId,
        label: plan.EntityId
    }));

    const answerSubject = answers['704b482d-ab03-471d-a1fd-8966e45b8b63']?.multipleAnswers;
    const [subjectsSelected,setSubjectsSelected] = useState([]);
    const [isSubjectsDisabled, setIsSubjectsDisabled] = useState(true);
    const subjectOptions = useSelector(state => {
        const selectedPlan = state.question.plans?.find(plan => plan.EntityId === answerPlan?.answer);
        return selectedPlan?.asignaturas?.map(op => ({
            value: op.EntityId,
            label: op.descripcion
        }));
    });

    useEffect(() => { 
        if (answerCareer) {
            const selectedCareer = careerOptions.find(option => option.label === answerCareer?.answer);
            if (selectedCareer && selectedCareer.value !== careerSelected?.value) {
                setCareerSelected(selectedCareer);
            }
        }
    }, [answerCareer, careerOptions]);

    useEffect(() => {
        if(careerSelected) {
            dispatch(getAllPlanes(userInfo, careerSelected.value));
            setIsPlanDisabled(false);
        } else { 
            setIsPlanDisabled(true);
            setPlanSelected(null);
        }
    }, [careerSelected, dispatch, userInfo]);

    useEffect(() => {
        if (planOptions && answerPlan) {
            const selectedPlan = planOptions.find(option => option.label === answerPlan?.answer);
            if (selectedPlan && selectedPlan.value !== planSelected?.value) {
                setPlanSelected(selectedPlan);
                setIsSubjectsDisabled(false);
            }
        } else {
            setIsSubjectsDisabled(true);
            setPlanSelected(null);
        }
    }, [planOptions, answerPlan]);

    useEffect(()=> {
        if(planSelected){
            setIsSubjectsDisabled(false)
            const matchedSubjects = subjectOptions.filter(option => 
                answerSubject?.some(answer => answer.answer === option.label)
            );
            setSubjectsSelected(matchedSubjects || null)
            
        }else{
            setIsSubjectsDisabled(true)
            setPlanSelected(null)
        }
    }, [planSelected])

    //Handlers
    const handleRadioChange = (event) => {
        const newValue = event.target.value;
        //setRadioValue(newValue);
        dispatch(setAnswer(idPregunta, newValue));
    };
    const handleCarreraChange = (idPreg,event) => {
        const newValue = event?.label || null;
        setCareerSelected(newValue);
        dispatch(setAnswer(idPreg, newValue));
        
    };
    const handlePlanChange = (idPreg, event) => {
        const newValue = event?.label || null;
        if (newValue !== planSelected?.value) {
            setPlanSelected(event);
            dispatch(setAnswer(idPreg, newValue));
        }
    };
    /*const handleAsignaturaChange = (idPreg, event) => {
        setSubjectsSelected(event);
        const subjEstructured = event.map(a => {
            const existingAnswer = answerSubject?.find(answer => answer.answer === a.value);
            
            return {
                questionId: idPreg,
                answerId: existingAnswer?.answerId || undefined,
                answer: a.value,
                multipleAnswers: undefined //existingAnswer?.multipleAnswers || undefined
            };
        });
        dispatch(setAnswer(idPreg, undefined, undefined, subjEstructured));
    };*/
    
    const handleAsignaturaChange = (idPreg, event) => {

        const previousSubjects = answerSubject?.map(subject => subject.answer) || [];
        const newSubjects = event.map(subject => subject.label);
    
        const deletedSubjects = previousSubjects.filter(prev => !newSubjects.includes(prev));
        if (deletedSubjects.length > 0) {
            for (let subject of deletedSubjects) {
                const answerId = answerSubject.find(ans => ans.answer === subject)?.answerId;
                if (answerId) {
                    dispatch(deleteAnswer(answerId,userInfo));
                }
            }
        }
    
        // Procesar las asignaturas seleccionadas
        setSubjectsSelected(event);
        const subjEstructured = event.map(a => {
            const existingAnswer = answerSubject?.find(answer => answer.answer === a.label);
            return {
                questionId: idPreg,
                answerId: existingAnswer?.answerId || undefined,
                answer: a.label,
                multipleAnswers: undefined
            };
        });
        dispatch(setAnswer(idPreg, undefined, undefined, subjEstructured));
    };
    

    const handleDetailChange = (SubjCod, GrandQuestionId, QuestionId, textValue) => {

        const answer = answers[GrandQuestionId];
        const specificAnsw = Object.values(answer.multipleAnswers).find(a => a.answer === SubjCod);

        if(specificAnsw){

            dispatch(setAnswerMultiple(GrandQuestionId,SubjCod,QuestionId,textValue));
        };
    }
    
    return (
        <div className="form-check">
            <label className='question-title'>{title}</label>
            <div style={{marginTop:'2%'}}>
                <input
                    type="radio"
                    id="si"
                    className='form-check-input'
                    name="titulo1"
                    value="1"
                    checked={answerValue === "1"}
                    onChange={handleRadioChange}
                />
                <label style={{marginBottom:'1%'}} className="form-check-label" htmlFor="si">Si</label>
            </div>
            <div>
                <input
                    type="radio"
                    id="no"
                    className='form-check-input'
                    name="titulo2"
                    value="0"
                    checked={answerValue === "0"}
                    onChange={handleRadioChange}
                />
                <label style={{marginBottom:'1%'}} className="form-check-label mb-3" htmlFor="no">No</label>
            </div>
                
            {answerValue === "1" && (

                <div className="additional-questions">
                    {additionalQuestions && additionalQuestions.map(pregunta => (
                        <React.Fragment key={pregunta.Id}>
                        {(() => {
                            switch (pregunta.Id) {
                                case '5f4e7417-e023-4da1-8c00-ee67e0ebb8cd': //Carrera
                                    return (
                                        <div className='mb-3'>
                                            <label className='nombre'> {pregunta.titulo} </label>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                placeholder = {'Seleccione la carrera'}
                                                isClearable= {true}
                                                isSearchable={true}
                                                name= {pregunta.Id}
                                                options={careerOptions}
                                                value={careerSelected}
                                                onChange={(value) => {
                                                    handleCarreraChange(pregunta.Id,value);
                                                }}
                                                components={makeAnimated}
                                            />
                                        </div>
                                    );
                                case '68b4a35f-3882-477d-97f9-f528057d1f10': //Plan
                                    return (
                                        <div className='mb-3'>
                                            <label className='nombre'> {pregunta.titulo} </label>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                placeholder={'Seleccione el plan'}
                                                isClearable
                                                isSearchable
                                                isDisabled={isPlanDisabled}
                                                name={idPregunta}
                                                value={planSelected}
                                                options={planOptions}
                                                onChange={(value) => {handlePlanChange(pregunta.Id,value)}}
                                                components={makeAnimated}
                                            />
                                        </div>
                                    );
                                case '704b482d-ab03-471d-a1fd-8966e45b8b63': //Asignatura
                                    return (
                                        <div className='mb-3'>
                                            <label className='nombre'> {pregunta.titulo} </label>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                placeholder={'Seleccione la/s asignatura/s correspondiente'}
                                                isClearable
                                                isSearchable
                                                isDisabled={isSubjectsDisabled}
                                                name={idPregunta}
                                                options={subjectOptions}
                                                isMulti
                                                value={subjectsSelected}
                                                onChange={(value) => {handleAsignaturaChange(pregunta.Id,value)}}
                                                components={makeAnimated}
                                            />
                                            <br></br>
                                            {subjectsSelected.length>0 ? 
                                                (<p>A continuacion debera completar el detalle especifico para cada asignatura seleccionada</p>) 
                                                : 
                                                (<></>) 
                                            }
                                            <Accordion alwaysOpen>
                                                {subjectsSelected && subjectsSelected.map(asig => {
                                                    // Encontrar las respuestas correspondientes a la asignatura
                                                    const matchingAnswer = answerSubject.find(ans => ans.answer === asig.label);
                                                    const raAnswer = matchingAnswer?.multipleAnswers?.['0a5ebea3-2d08-46b9-9321-1bb1c74c5bf9']?.answer || '';
                                                    const creditosAnswer = matchingAnswer?.multipleAnswers?.['781f0b27-c2e8-458c-818b-5b1fba025cd0']?.answer || '';

                                                    return (
                                                        <Accordion.Item eventKey={asig.value} key={asig.value}>
                                                            <Accordion.Header>{asig.label}</Accordion.Header>
                                                            <Accordion.Body>
                                                                <div> {/*RA*/}
                                                                    <Form.Group className="tipo-de-credencial-parent mb-3">
                                                                        <Form.Label className='nombre'>RA Asignatura</Form.Label>
                                                                        <Form.Control
                                                                            as="textarea"
                                                                            rows={3}
                                                                            placeholder="Ingresa tu respuesta"
                                                                            required
                                                                            defaultValue={raAnswer} // Cargar valor dinámico
                                                                            onChange={(e) => handleDetailChange(asig.label, pregunta.Id, '0a5ebea3-2d08-46b9-9321-1bb1c74c5bf9', e.target.value)}
                                                                        />
                                                                    </Form.Group>
                                                                </div>
                                                                <div> {/*Creditos*/}
                                                                    <Form.Group className="tipo-de-credencial-parent mb-3">
                                                                        <Form.Label className='nombre'>Cantidad de créditos</Form.Label>
                                                                        <Form.Control
                                                                            as="textarea"
                                                                            rows={3}
                                                                            placeholder="Ingresa tu respuesta"
                                                                            required
                                                                            defaultValue={creditosAnswer} // Cargar valor dinámico
                                                                            onChange={(e) => handleDetailChange(asig.label, pregunta.Id, '781f0b27-c2e8-458c-818b-5b1fba025cd0', e.target.value)}
                                                                        />
                                                                    </Form.Group>
                                                                </div>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    );
                                                })}
                                            </Accordion>

                                        </div>
                                    );
                                case 'fb9eab06-0fd8-45f1-947d-9f11dc53275d': //Observacion
                                    return (
                                        <Texto
                                        title={pregunta.titulo}
                                        idPregunta={pregunta.Id}
                                    />);
                                default:
                                    return null;
                                }
                        })()}
                        </React.Fragment>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ReconocimientoCreditos;
