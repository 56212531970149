const initialState = {
    proposals: [],
    loading: false,
    error: null,
    jury: [],
    proposalJury: [],
    externalUsers: []
};

const proposalReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CREATE_PROPOSAL_SUCCESS':
            return {
                ...state,
                proposals: [...state.proposals, action.payload],
                loading: false,
                error: null,
            };
        case 'UPDATE_PROPOSAL_SUCCESS':
            return {
                ...state,
                proposals: [...state.proposals, action.payload],
                loading: false,
                error: action.payload,
            };

        case 'HANDLE_PROPOSAL':
            return {
                ...state,
                loading: true,
                error: null,
            };
        case 'EVALUATE_PROPOSAL_SUCCESS':
            return {
                ...state,
                proposals: [...state.proposals, action.payload],
                loading: false,
                error: null,
            };
        case 'HANDLE_PROPOSAL_FAIL':
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case 'HANDLE_PROPOSAL_ERROR':
            return {
                ...state,

                loading: false,
                error: action.payload,
            };
        case 'GET_ALL_PROPOSALS':
            return {
                ...state,
                loading: true,
                error: null,
            };
        case 'GET_ALL_PROPOSALS_SUCCESS':
            return {
                ...state,
                proposals: action.payload,
                loading: false,
                error: null,
            };
        case 'GET_ALL_PROPOSALS_ERROR':
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case 'DOWNLOAD_PROPOSAL':
        case 'DOWNLOAD_PROPOSAL_SUCCESS':
            return {
                ...state,
                loading: true,
                error: null,
            };
        case 'DOWNLOAD_PROPOSAL_FAIL':
        case 'DOWNLOAD_PROPOSAL_ERROR':
            return {
                ...state,
                loading: false,
                error: null,
            };
        case 'GET_ALL_JURY':
        case 'GET_ALL_EXTERNAL_JURY':
            return {
                ...state,
                loading: true,
                error: null,
            };
        case 'GET_ALL_JURY_SUCCESS':
            return {
                ...state,
                jury: action.payload,
                loading: false,
                error: null,
            };
        case 'GET_ALL_EXTERNAL_JURY_SUCCESS':
            return {
                ...state,
                externalUsers: action.payload,
                loading: false,
                error: null,
            };
        case 'GET_ALL_JURY_ERROR':
        case 'GET_ALL_EXTERNAL_JURY_ERROR':
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case 'GET_PROPOSAL_JURY':
            return {
                ...state,
                loading: true,
                error: null,
            };
        case 'GET_PROPOSAL_JURY_SUCCESS':
            return {
                ...state,
                proposalJury: action.payload,
                loading: false,
                error: null,
            };
        case 'GET_PROPOSAL_JURY_ERROR':
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case 'SET_PROPOSAL_JURY':
            return {
                ...state,
                loading: true,
                error: null,
            };
        case 'SET_PROPOSAL_JURY_SUCCESS':
            return {
                ...state,
                //proposals: [...state.proposals, action.payload],
                loading: false,
                error: null,
            };
        case 'SET_PROPOSAL_JURY_ERROR':
        case 'SET_PROPOSAL_JURY_FAIL':
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
};

export default proposalReducer;
