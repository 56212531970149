import React from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

const QuestionCard = ({ questions, currentStep }) => {
    return (
        questions[currentStep] && (
        <Card className='card-question'>
            <Card.Title className='card-title'>
                <FontAwesomeIcon icon={faQuestionCircle} /> ¿De qué se trata?
            </Card.Title>
            <Card.Body>
                <Card.Text>
                    <span className='card-body-title'>{questions[currentStep].titulo}</span>
                    {questions[currentStep].preguntas && questions[currentStep].preguntas.map(pregunta => (
                        <div key={pregunta.Id} className='card-body-detail'>                          
                            <div dangerouslySetInnerHTML={{ __html: pregunta.ayuda }} />                          
                            {pregunta.subpreguntas && pregunta.subpreguntas.length > 0 && pregunta.subpreguntas.map(subpregunta => (
                                <div key={subpregunta.Id} className='card-body-detail'>                                   
                                    <div dangerouslySetInnerHTML={{ __html: subpregunta.ayuda }} />
                                </div>
                            ))}
                        </div>
                    ))}
                </Card.Text>
            </Card.Body>
        </Card>)
    );
};

export default QuestionCard;
