import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Button } from 'react-bootstrap';
import { fetchMicrocredentialTypes } from '../actions/typeActions';
import { createRequest, getFileRequest } from '../actions/requestActions';
import arrowRightImage from '../assets/arrow-small-right.png';
import { useNavigate } from 'react-router-dom';
import AttachmentButton from './AttachmentButton';
import { RequestStatus } from '../enums/requestStatus';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

const RequestDetail = () => {
    const { requestId } = useParams();
    //const dispatch = useDispatch();
    const { requests } = useSelector(state => state.request);
    const [request, setRequest] = useState(null);
    const [observacion, setObservacion] = useState('');
    const dispatch = useDispatch();

    const { userInfo } = useSelector(state => state.user);

    const isJuryRole = userInfo.roles != undefined ? (userInfo.roles.includes('jurado') || userInfo.roles.includes('AdminMicrocredencial')) : false;

    const navigate = useNavigate();
    useEffect(() => {
        const foundRequest = requests.find(x => x.Id === requestId);
        setRequest(foundRequest);
    }, [requestId, requests]);

    const handleBack = () => {
        navigate('/backoffice');
    };

    const handleSubmit = (estadoId) => {

        const requestData = {
            ...request,
            observacion: observacion,
            estado:{
                Id: estadoId
            }
        };

        dispatch(createRequest(requestData, null, userInfo, navigate, true));
    };

    const downloadFile = () => {
        dispatch(getFileRequest(request.resultadosArchivo, userInfo));
    };

    return (
        <div>
            <div style={{ marginBottom: '20px' }}>
                <section className="container1">
                    <div className='row'>
                        <a className="btn_back" onClick={handleBack}>
                            Volver al home
                        </a>
                    </div>
                    <section className='mt-5'>
                        {request && (
                            <>
                                <div>
                                    <div className='row mb-5'>
                                        <h2>Detalle de {request.nombre} </h2>
                                        <p className='revisar'>Revisa el detalle de la solicitud para poder tomar una acción correspondiente.</p>
                                    </div>
                                    <div className="containerDetail">
                                        <div className="row">
                                            <div className="col-3 columna1detail">
                                                <div className='form-group mb-3'>
                                                    <p className='detail-title'>Nombre y apellido del solicitante</p>
                                                    <span>{request.usuario.EntityId}</span>
                                                </div>
                                                <div className='form-group mb-3'>
                                                    <p className='detail-title'>Fecha de creación de la solicitud</p>
                                                    <span>{moment(request.fechaSolicitud).format('DD-MM-YYYY')}</span>
                                                </div>
                                                <div className='form-group mb-3'>
                                                    <p className='detail-title'>Estado</p>
                                                    <span>{request.estado.estado}</span>
                                                </div>
                                            </div>
                                            <div className='col-8 columna1detail'>
                                                <div className='row'>
                                                    <div className='form-group mb-3'>
                                                        <p className='detail-title'>Formato de microcredencial</p>
                                                        <span>{request.microcredencial.tipoNombre}</span>
                                                    </div>
                                                    <div className='form-group mb-3'>
                                                        <p className='detail-title'>Resultados de aprendizaje</p>
                                                        <span>{request.resultadosAprendizaje}</span>
                                                    </div>
                                                    <div className='form-group mb-3'>
                                                        <p className='detail-title'>Mapeo de los contenidos en relación con los resultados de aprendizaje</p>
                                                        <span>{request.resultadosContenido}</span>
                                                    </div>
                                                    {request.resultadosArchivo && <div className='form-group mb-3'>
                                                        <p className='detail-title'>Archivo adjunto</p>
                                                        <label htmlFor="file-input" className="attachment-button mb-3" onClick={() => downloadFile()}>
                                                            <FontAwesomeIcon icon={faDownload} className="attachment-icon" />
                                                            <span>{request.resultadosArchivo}</span>
                                                        </label>
                                                        
                                                    </div>}
                                                    
                                                    <div className='form-group mb-3'>
                                                        <p className='detail-title'>Destinatarios del proyecto</p>
                                                        <span>{request.destinatarios}</span>
                                                    </div>
                                                    <div className='form-group mb-3'>
                                                        <p className='detail-title'>Pertinencia del proyecto</p>
                                                        <span>{request.pertinencia}</span>
                                                    </div>
                                                    <div className='form-group mb-3'>
                                                        <p className='detail-title'>Otorga creditos academicos</p>
                                                        <span>{request.otorgaCreditos? "Si" : "No"}</span>
                                                    </div>
                                                    {/*isJuryRole &&
                                                        <div className='form-group mb-3'>
                                                        <p className='detail-title'>Notas</p>
                                                        <Form.Control as="textarea" rows={3} placeholder="Breve explicación de la resolución de la revisión de la solicitud." required value={observacion} onChange={(e) => setObservacion(e.target.value)} />
                                                        </div>*/
                                                    }
                                                    {(request.estado.Id.toUpperCase() == RequestStatus.Pendiente.id && isJuryRole)? 
                                                        <div className='form-group mb-3'>
                                                            <p className='detail-title'>Notas</p>
                                                            <Form.Control as="textarea" rows={3} placeholder="Breve explicación de la resolución de la revisión de la solicitud." required value={observacion} onChange={(e) => setObservacion(e.target.value)} />
                                                        </div>
                                                        : 
                                                        request.observacion &&
                                                        <div className='form-group mb-3'>
                                                            <p className='detail-title'>Observaciones</p>
                                                            <span>{request.observacion}</span>
                                                        </div>
                                                    }
                                                </div>
                                                {isJuryRole && request.estado.Id.toUpperCase() == RequestStatus.Pendiente.id &&
                                                <div className='row'>
                                                    <div className='col-6'></div>
                                                    <div className='col-3'><button className='btnReject' onClick={() => { handleSubmit(RequestStatus.Rechazada.id) }} >Desaprobar</button></div>
                                                    <div className='col-3'><button className='btnApprove' onClick={() => { handleSubmit(RequestStatus.Aprobada.id) }}>Aprobar</button></div>
                                                </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </section>
                </section>
            </div>
        </div>
    );
};

export default RequestDetail;
