import { notifySuccess, notifyError } from '../components/Toast';

const apiUrl = `${process.env.REACT_APP_API_BASE_URL}microcredenciales/propuestas/save`;

const fetchProposal = async (requestData, user) => {
  const response = await fetch(apiUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.token
    },
    body: JSON.stringify(requestData)
  });
  return response;
};

export const handleProposal = (requestData, user, navigate, successType, successRoute) => async (dispatch) => {
  try {
    dispatch({ type: 'HANDLE_PROPOSAL' });
    const response = await fetchProposal(requestData, user);

    if (response.ok) {
      const proposalId = await response.json();
      requestData.Id = proposalId;
      dispatch({ type: successType, payload: requestData });
      //notifySuccess("La operación fue realizada con éxito");
      navigate(successRoute);
    } else {
      const errorData = await response.statusText;
      dispatch({ type: 'HANDLE_PROPOSAL_FAIL', payload: errorData });
     // notifyError("Se ha producido un error. Intente nuevamente");
    }
  } catch (error) {
    dispatch({ type: 'HANDLE_PROPOSAL_ERROR', payload: error.message });
    notifyError("Se ha producido un error. Intente nuevamente");
  }
};

export const handleProposalCreation = (requestData, user, navigate, successType) => async (dispatch) => {
  try {
    dispatch({ type: 'HANDLE_PROPOSAL' });
    const response = await fetchProposal(requestData, user);

    if (response.ok) {
      const proposalId = await response.json();
      requestData.Id = proposalId;
      dispatch({ type: successType, payload: requestData });
      navigate(`/backoffice/applicationform/${requestData.Id}`);
    } else {
      const errorData = await response.statusText;
      dispatch({ type: 'HANDLE_PROPOSAL_FAIL', payload: errorData });
    }
  } catch (error) {
    dispatch({ type: 'HANDLE_PROPOSAL_ERROR', payload: error.message });
  }
};

export const createProposal = (requestData, user, navigate) => handleProposalCreation(
  requestData,
  user,
  navigate,
  'CREATE_PROPOSAL_SUCCESS'
);

export const evaluateProposal = (requestData, user, navigate) => handleProposal(
  requestData,
  user,
  navigate,
  'EVALUATE_PROPOSAL_SUCCESS',
  `/backoffice/applicationformJury/${requestData.Id}`
);

export const evaluateProposalFinal = (requestData, user, navigate) => handleProposal(
  requestData,
  user,
  navigate,
  'EVALUATE_PROPOSAL_SUCCESS',
  `/backoffice/proposal-detail/${requestData.Id}`
);

export const updateProposal = (requestData, user, navigate) => handleProposal(
  requestData,
  user,
  navigate,
  'UPDATE_PROPOSAL_SUCCESS',
  `/backoffice`
);

export const setJuryEvaluation = (proposalId, user, navigate) => async (dispatch) => {
  try {
    dispatch({ type: 'SET_JURY_EVALUATION' });
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}microcredenciales/jueces/evaluacion/${proposalId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.token
      }      
    });

    if (response.ok) {
      //const proposalId = await response.json();
     // requestData.Id = proposalId;
      notifySuccess("La evaluación fue guardada exitosamente!");
      dispatch({ type: 'JURY_EVALUATION_SUCCESS' });
      navigate('/backoffice');
    } else {
      const errorData = await response.statusText;
      dispatch({ type: 'JURY_EVALUATION_FAIL', payload: errorData });
    }
  } catch (error) {
    dispatch({ type: 'JURY_EVALUATION_ERROR', payload: error.message });
  }
};

export const getAllProposals = (user) => {
  return async (dispatch) => {
    try {
      dispatch({ type: 'GET_ALL_PROPOSALS' });

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}microcredenciales/propuestas?fechaDesde&fechaHasta&estado&tipoMicro`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + user.token
        },
      });
      const requestData = await response.json();

      dispatch({
        type: 'GET_ALL_PROPOSALS_SUCCESS',
        payload: requestData,
      });
    } catch (error) {
      dispatch({
        type: 'GET_ALL_PROPOSALS_ERROR',
        payload: error.message,
      });
    }
  };
};

export const downloadProposal = (user, proposalId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: 'DOWNLOAD_PROPOSAL' });

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}microcredenciales/respuestas/PDF?idPropuesta=${proposalId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + user.token
        },
      });
      
  
      if (response.ok) {
        const blob = await response.blob();
        dispatch({ type: 'DOWNLOAD_PROPOSAL_SUCCESS' });
         
          const downloadUrl = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = downloadUrl;
          a.download = 'PropuestaDesarrollo.pdf';
          document.body.appendChild(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(downloadUrl);
      } else {
        const errorData = await response.statusText;
        dispatch({ type: 'DOWNLOAD_PROPOSAL_FAIL', payload: errorData });
      }

    } catch (error) {
      dispatch({
        type: 'DOWNLOAD_PROPOSAL_ERROR',
        payload: error.message,
      });
    }
  };
};

export const getAllJury = (user) => {
  return async (dispatch) => {
    try {
      dispatch({ type: 'GET_ALL_JURY' });

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}microcredenciales/usuarios?rol=jurado`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + user.token
        },
      });
      const requestData = await response.json();

      dispatch({
        type: 'GET_ALL_JURY_SUCCESS',
        payload: requestData,
      });
    } catch (error) {
      dispatch({
        type: 'GET_ALL_JURY_ERROR',
        payload: error.message,
      });
    }
  };
};

export const getAllExternalJury = (user) => {
  return async (dispatch) => {
    try {
      dispatch({ type: 'GET_ALL_EXTERNAL_JURY' });

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}microcredenciales/usuarios/externo`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + user.token
        },
      });
      const requestData = await response.json();

      dispatch({
        type: 'GET_ALL_EXTERNAL_JURY_SUCCESS',
        payload: requestData,
      });
    } catch (error) {
      dispatch({
        type: 'GET_ALL_EXTERNAL_JURY_ERROR',
        payload: error.message,
      });
    }
  };
};

export const getProposalJury = (user, proposalId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: 'GET_PROPOSAL_JURY' });

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}microcredenciales/propuestas/${proposalId}/jueces`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + user.token
        },
      });
      const requestData = await response.json();

      dispatch({
        type: 'GET_PROPOSAL_JURY_SUCCESS',
        payload: requestData,
      });
    } catch (error) {
      dispatch({
        type: 'GET_PROPOSAL_JURY_ERROR',
        payload: error.message,
      });
    }
  };
};

export const saveProposalJury = (proposalId, juryData, user) => async (dispatch) => {
  try {
    dispatch({ type: 'SET_PROPOSAL_JURY' });
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}microcredenciales/jueces/roles/${proposalId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.token
      },
      body: JSON.stringify(juryData)      
    });

    if (response.ok) {     
      dispatch({ type: 'SET_PROPOSAL_JURY_SUCCESS' }); 
      notifySuccess("Evaluadores guardados exitosamente!")
    } else {
      const errorData = await response.statusText;
      dispatch({ type: 'SET_PROPOSAL_JURY_FAIL', payload: errorData });
      notifyError("Error al guardar evaluadores")
    }
  } catch (error) {
    dispatch({ type: 'SET_PROPOSAL_JURY_ERROR', payload: error.message });
    notifyError("Error al guardar evaluadores")
  }
};


