import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Spinner, Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser, loginExternalUser } from '../actions/userActions';
import { useNavigate } from 'react-router-dom';

const LoginModal = ({ show, onHide }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loading, userInfo, error } = useSelector(state => state.user);

    useEffect(() => {
        if (userInfo) {
            navigate('/backoffice');
            onHide();
        }
    }, [userInfo]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(username.toLocaleLowerCase().includes("uai.edu.ar")){
            dispatch(loginUser({ username, password }));     
        }
        else {
            dispatch(loginExternalUser({ username, password }));     
        }            
    };
    
    return (
        <Modal show={show} onHide={onHide} aria-labelledby="contained-modal-title-vcenter" centered className='modalLogin' id="ContainerLog">
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className='iniciarSesion'>
                    INICIAR SESIÓN
                </Modal.Title>                
            </Modal.Header>
            <Modal.Body>
                {error && (
                    <Alert variant="danger">
                        {error}
                    </Alert>
                )}
                {loading ? (
                    <div className="text-center">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                ) : (<div id="ContainerLogin">                    
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="username" className="mb-3">
                            <Form.Control type="text" placeholder="Usuario" required
                                value={username} onChange={e => setUsername(e.target.value)} />
                        </Form.Group>
                        <Form.Group controlId="password" className="mb-3">
                            <Form.Control type="password" placeholder="Contraseña" required
                                value={password} onChange={e => setPassword(e.target.value)} />
                        </Form.Group>
                        <Button variant="primary" type="submit" id="ButtonLogin">
                            Iniciar sesión
                        </Button>
                    </Form>
                    </div>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default LoginModal;
