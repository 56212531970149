import Dashboard from "./Dashboard";

const UserBackoffice = () => {
  
    return (
        <div style={{marginBottom:'50px'}}>
             <Dashboard />           
        </div>
    );
};
export default UserBackoffice;