import { combineReducers } from 'redux';
import userReducer from './userReducer';
import requestReducer from './requestReducer';
import typeReducer  from './typeReducer';
import questionReducer  from './questionReducer';
import proposalReducer from './proposalReducer';

export default combineReducers({
    user: userReducer,
    request: requestReducer,
    types: typeReducer,
    question: questionReducer,
    proposal: proposalReducer    
});