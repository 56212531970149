import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setAnswer } from '../../actions/questionActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';

const Archivo = ({ title, idPregunta }) => {
  const dispatch = useDispatch();
  const [fileName, setFileName] = useState('');

  const answers = useSelector(state => state.question.answers);
  const answerValue = answers[idPregunta]?.fileName || '';

  useEffect(() => {    
    if (answerValue) {
      setFileName(answerValue);
    }
  }, [answerValue]);

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
      dispatch(setAnswer(idPregunta, file));
    }
  };

  return (
    <div>
      <Form.Group className="tipo-de-credencial-parent mb-3">
        <Form.Label className='nombre'>
          <div dangerouslySetInnerHTML={{ __html: title }} />
        </Form.Label>
        <input
          type="file"
          id="file-input"
          style={{ display: 'none' }}
          onChange={handleFileSelect}
        />
        <label htmlFor="file-input" className="attachment-button mb-3">
          <FontAwesomeIcon icon={faPaperclip} className="attachment-icon" />
          {fileName || "Adjuntar archivo"}
        </label>
      </Form.Group>
    </div>
  );
};

export default Archivo;
