import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Button, Modal, Form } from 'react-bootstrap';
import { getAllRequests } from '../actions/requestActions';
import { useDispatch, useSelector } from 'react-redux';
import { RequestStatus } from '../enums/requestStatus';
import arrowRightImage from '../assets/arrow-small-right.png';
import mcProceso from '../assets/mcProceso.png';
import moment from 'moment';
import { createProposal, getAllProposals, evaluateProposal, getAllJury, getAllExternalJury, saveProposalJury, getProposalJury, evaluateProposalFinal } from '../actions/proposalActions';
import { ProposalStatus } from '../enums/proposalStatus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { notifySuccess, notifyError } from './Toast';
import { createExternalUser } from '../actions/userActions';
import { JuryRoles } from '../enums/juryRoles';

const Dashboard = () => {
    const dispatch = useDispatch();
    const { requests, loading, error } = useSelector(state => state.request);
    const { proposals, jury, proposalJury, externalUsers } = useSelector(state => state.proposal);
    const { userInfo } = useSelector(state => state.user);
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('solicitudes');
    const [filter, setFilter] = useState('all');
    const [externalName, setName] = useState('');
    const [externalSurname, setSurname] = useState('');
    const [externalEmail, setEmail] = useState('');
    const [activeProposal, setActiveProposal] = useState('')
    const [showModal, setShowModal] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedSupremeUsers, setSelectedSupremeUsers] = useState([]);
    const [selectedExternalUsers, setSelectedExternalUsers] = useState([]);

    const filterItemsByState = (items, filter) => {
        if (filter === 'all') return items;
        return items.filter(item => item.estado.Id.toUpperCase() === filter);
    };
    const toggleModal = () => {
        setShowModal(!showModal);
        cleanModalForm();
    };

    const cleanModalForm = () => {
        setSelectedUsers([]);
        setSelectedSupremeUsers([]);
        setSelectedExternalUsers([]);
        setSurname('');
        setName('')
        setEmail('')
    };

    const saveSelectedUsers = () => {
        const unifiedList = [];

        if (selectedSupremeUsers.length == 0 || selectedUsers.length == 0) {
            notifyError("Debe seleccionar al menos un evaluador y un responsable para dictaminar la propuesta")
            return;
        }

        selectedUsers.forEach(user => {
            unifiedList.push({
                evaluacion: {},
                idUsuario: user,
                usuarioExterno: {},
                rol: {
                    Id: JuryRoles.Evaluador.id,
                    nombre: JuryRoles.Evaluador.nombre
                }
            });
        });

        selectedExternalUsers.forEach(user => {
            unifiedList.push({
                evaluacion: {},
                idUsuario: null,
                usuarioExterno: {
                    Id: user
                },
                rol: {
                    Id: JuryRoles.Evaluador.id,
                    nombre: JuryRoles.Evaluador.nombre
                }
            });
        });

        selectedSupremeUsers.forEach(user => {
            unifiedList.push({
                evaluacion: {},
                idUsuario: user,
                usuarioExterno: {},
                rol: {
                    Id: JuryRoles.Dictaminador.id,
                    nombre: JuryRoles.Dictaminador.nombre
                }
            });
        });


        dispatch(saveProposalJury(activeProposal, unifiedList, userInfo));
        dispatch(getAllProposals(userInfo));
        setShowModal(false);
    };
    const filteredRequests = filterItemsByState(requests, filter);
    const filteredProposals = filterItemsByState(proposals, filter);

    const isJuryRole = userInfo.roles != undefined ? (userInfo.roles.includes('jurado') || userInfo.roles.includes('AdminMicrocredencial')) : false;
    const isAdminRole = userInfo.roles != undefined ? userInfo.roles.includes('AdminMicrocredencial') : false;

    const handleFilterClick = (status, event) => {
        event.preventDefault();
        setFilter(status);
    };

    const handleRequestDetail = (requestId) => {
        navigate(`/backoffice/request-detail/${requestId}`);
    };

    useEffect(() => {
        dispatch(getAllRequests(userInfo));
        dispatch(getAllProposals(userInfo));
        dispatch(getAllJury(userInfo));
        dispatch(getAllExternalJury(userInfo));
        setFilter('all');

    }, [activeTab, dispatch, userInfo]);

    useEffect(() => {
        if (proposalJury && proposalJury.length > 0) {

            const juryUserIds = proposalJury
                .filter(jury => jury.rol && jury.rol.Id.toUpperCase() == JuryRoles.Evaluador.id && jury.usuarioExterno == null)
                .map(jury => jury.idUsuario);

            const jurySupremeUserIds = proposalJury
                .filter(jury => jury.rol && jury.rol.Id.toUpperCase() == JuryRoles.Dictaminador.id)
                .map(jury => jury.idUsuario);

            const users = proposalJury
                .filter(jury => jury.usuarioExterno)
                .map(jury => jury.usuarioExterno);

            setSelectedExternalUsers(users);

            users.forEach(us => {
                juryUserIds.push(us.Id)
            });

            setSelectedUsers(juryUserIds);
            setSelectedSupremeUsers(jurySupremeUserIds);
        }
    }, [proposalJury]);

    const handleLoadApplicationForm = (requestId) => {

        const requestData = {
            solicitud: {
                Id: requestId
            },
            estado: {
                Id: ProposalStatus.EnEdicion.id
            }
        };

        dispatch(createProposal(requestData, userInfo, navigate));
    };

    const handleLoadApplicationFormEdition = (requestId, proposalId) => {

        const requestData = {
            Id: proposalId,
            solicitud: {
                Id: requestId
            },
            estado: {
                Id: ProposalStatus.EnEdicion.id
            }
        };

        dispatch(createProposal(requestData, userInfo, navigate));
    };

    const handleLoadApplicationFormJuryEdition = (requestId, proposal) => {

        let estadoId = ProposalStatus.EnEvaluacion.id;
        //proposal.esJuezSupremo = false;

        if (proposal.esUsuarioResponsable) {
            estadoId = ProposalStatus.EnDictamenFinal.id;
        }

        const requestData = {
            Id: proposal.Id,
            solicitud: {
                Id: requestId
            },
            estado: {
                Id: estadoId
            }
        };

        dispatch(evaluateProposal(requestData, userInfo, navigate));
    };

    const hasFinalStatus = (proposal) => {

        var isValid = false;
        switch (proposal.estado.Id.toUpperCase()) {
            case ProposalStatus.AceptadaConObservaciones.id:
            case ProposalStatus.Aprobada.id:
            case ProposalStatus.Rechazada.id:
                isValid = true;
                break;

            default:
                break;
        }

        return isValid;
    };

    const handleLoadProposalDetail = (requestId, proposal) => {

        let estadoId = ProposalStatus.EnDictamenFinal.id;

        const requestData = {
            Id: proposal.Id,
            solicitud: {
                Id: requestId
            },
            estado: {
                Id: estadoId
            }
        };

        dispatch(evaluateProposalFinal(requestData, userInfo, navigate));
    };

    const handleLoadEvaluators = (proposal) => {
        setActiveProposal(proposal.Id);
        dispatch(getProposalJury(userInfo, proposal.Id));
        toggleModal();
    };

    const handleUserSelection = (userId) => {
        setSelectedUsers(prevUsers => {
            if (prevUsers.includes(userId)) {
                return prevUsers.filter(id => id != userId);
            } else {
                return [...prevUsers, userId];
            }
        });
    };
    const handleExternalUserSelection = (userId) => {        
        setSelectedExternalUsers(prevUsers => {
            if (prevUsers.includes(userId)) {
                return prevUsers.filter(id => id != userId);
            } else {
                return [...prevUsers, userId];
            }
        });
    };

    const handleSupremeUserSelection = (userId) => {
        setSelectedSupremeUsers(prevUsers => {
            if (prevUsers.includes(userId)) {
                return prevUsers.filter(id => id != userId);
            } else {
                if (prevUsers.length >= 1) {
                    notifyError('Solo se puede seleccionar un responsable.');
                    return prevUsers;
                } else {
                    return [...prevUsers, userId];
                }
            }
        });
    };

    const handleSubmitExternalUser = (e) => {
        e.preventDefault();

        if (externalName == '' || externalSurname == '' || externalEmail == '') {
            notifyError('Faltan completar datos de usuario externo.');
            return;
        }
        const requestData = {
            nombre: externalName,
            apellido: externalSurname,
            email: externalEmail
        };

        dispatch(createExternalUser(requestData, userInfo, activeProposal));
        setSurname('');
        setName('');
        setEmail('');
    };

    let pendingCount = 0;
    let approvedCount = 0;
    let rejectedCount = 0;

    requests.forEach(request => {
        if (request.estado.Id.toUpperCase() === RequestStatus.Pendiente.id) {
            pendingCount++;
        } else if (request.estado.Id.toUpperCase() === RequestStatus.Aprobada.id) {
            approvedCount++;
        } else if (request.estado.Id.toUpperCase() === RequestStatus.Rechazada.id) {
            rejectedCount++;
        }
    })

    let pendingProposalCount = 0;
    let approvedProposalCount = 0;
    let rejectedProposalCount = 0;
    let inEditionProposalCount = 0;

    proposals.forEach(pr => {
        if (pr.estado.Id.toUpperCase() === ProposalStatus.Pendiente.id) {
            pendingProposalCount++;
        } else if (pr.estado.Id.toUpperCase() === ProposalStatus.Aprobada.id) {
            approvedProposalCount++;
        } else if (pr.estado.Id.toUpperCase() === ProposalStatus.Rechazada.id) {
            rejectedProposalCount++;
        } else if (pr.estado.Id.toUpperCase() === ProposalStatus.EnEdicion.id) {
            inEditionProposalCount++;
        }
    })

    return (
        <>
            <Modal show={showModal} onHide={toggleModal} dialogClassName="custom-modal-dialog">
                <Modal.Header closeButton>
                    <Modal.Title>Asignar evaluadores</Modal.Title>
                </Modal.Header>
                <Modal.Body className="custom-modal-body">
                    <div className='row'>
                        <div className='col-md-6'>
                            <Form.Group className="mb-3" controlId="fullName">
                                <Form.Label>Nombre</Form.Label>
                                <Form.Control type="text" placeholder="Nombre" required value={externalName} onChange={(e) => setName(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="fullName">
                                <Form.Label>Apellido</Form.Label>
                                <Form.Control type="text" placeholder="Apellido" required value={externalSurname} onChange={(e) => setSurname(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" placeholder="Email" required value={externalEmail} onChange={(e) => setEmail(e.target.value)} />
                            </Form.Group>
                            <Button className='btnFinalizarDictamen' onClick={handleSubmitExternalUser}>Agregar externo</Button>
                        </div>
                        <div className='col-md-6'>
                            <div className='row'>
                                <table>
                                    <thead>
                                        <tr className="table_head">
                                            <th>Evaluador</th>
                                            <th>Responsable del dictamen</th>
                                            <th>Usuario</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {loading ? <></> : jury.map((user, index) => (
                                            <tr key={index}>
                                                <td><input type="checkbox" checked={selectedUsers.includes(user.idUsuario.toLowerCase())} onChange={() => handleUserSelection(user.idUsuario.toLowerCase())} /></td>
                                                <td><input type="checkbox" checked={selectedSupremeUsers.includes(user.idUsuario.toLowerCase())} onChange={() => handleSupremeUserSelection(user.idUsuario.toLowerCase())} /></td>
                                                <td>{user.idUsuario.toLowerCase()}</td>
                                            </tr>
                                        ))}
                                        {loading ? <></> : externalUsers.map((user, index) => (
                                            <tr key={index}>
                                                <td><input type="checkbox" checked={selectedExternalUsers.includes(user.Id.toLowerCase())} onChange={() => handleExternalUserSelection(user.Id.toLowerCase())} /></td>
                                                <td></td>
                                                <td>{user.nombre.toLowerCase()} {user.apellido.toLowerCase()} - {user.email.toLowerCase()}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className='row'>

                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={toggleModal}>Cancelar</Button>
                    <Button variant="primary" onClick={saveSelectedUsers}>Asignar</Button>
                </Modal.Footer>
            </Modal>
            {!isJuryRole ? (
                <section className="container bienvenida">
                    <h2>¡Bienvenido/a, <span style={{ color: "#F20A61" }}>{userInfo.userName}</span>!</h2>
                    <div className="bienvenida1">
                        <div className='col-12'>
                            <div className='row'>
                                <div className='col-6'>
                                    <p className='parrafo-bienvenida'>¡Gracias por mostrar interés en diseñar una microcredencial!<br /><br /> En la UAI, apreciamos tu iniciativa y te brindamos el respaldo necesario para crear una propuesta de alto valor y calidad, alineada con nuestros estándares educativos de excelencia.
                                        <br /><br />
                                        Por favor, envianos tu propuesta completando la solicitud inicial. Considerá los tiempos previstos para cada etapa ya que son fijos e inamovibles y organizan todo el proceso. Compartimos una línea de tiempo para que puedas agendarlos.</p>
                                    <br />
                                </div>
                                {requests.length > 0 &&
                                    <div className='col-6'>
                                        <img src={mcProceso} style={{ width: '100%' }} alt="" />
                                    </div>
                                }
                            </div>
                            <div className='row'>
                                <div className='col-6'>
                                    <div className="alcance_content mt-3">
                                        <img src={arrowRightImage} alt="" />
                                        <p>La aprobación de la solicitud inicial es un requisito para presentar el proyecto completo.</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-6'>
                                        <div className="alcance_content mt-3">
                                            <img src={arrowRightImage} alt="" />
                                            <p>La aprobación de la solicitud inicial es un requisito para presentar el proyecto completo.</p>
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className="caption1 mt-3">
                                            <p href="#">Comenzá por Aquí</p>
                                            <Link to="/backoffice/new-request" >

                                                <img src={arrowRightImage} alt="" style={{ marginLeft: '40px' }} />
                                                <Button variant="primary" className="btn">
                                                    Solicitud inicial
                                                </Button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section >)
                :
                (<section className="container bienvenida">
                    <h2>¡Bienvenido/a, <span style={{ color: "#F20A61" }}>{userInfo.userName}</span>!</h2>
                    <div>
                        <div className='row'>
                            <div className='col-12'>
                                <div className="first_col">
                                    <p>Aquí podrás ver listadas las solicitudes enviadas por los creadores de las microcredenciales para ser evaluadas.</p>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-6'>
                                <div className="alcance_content mt-3">
                                    <img src={arrowRightImage} alt="" />
                                    <p>La aprobación de la solicitud inicial es un requisito para presentar el proyecto completo.</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6'>
                                    <div className="alcance_content mt-3">
                                        <img src={arrowRightImage} alt="" />
                                        <p>La aprobación de la solicitud inicial es un requisito para presentar el proyecto completo.</p>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className="caption1 mt-3">
                                        <p href="#">Comenzá por Aquí</p>
                                        <Link to="/backoffice/new-request" >

                                            <img src={arrowRightImage} alt="" style={{ marginLeft: '40px' }} />
                                            <Button variant="primary" className="btn">
                                                Solicitud inicial
                                            </Button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >)
            }
            {requests.length > 0 ?
                <section className="container mt-5">
                    <div className='col-12 mb-5'>
                        <div className='row'>
                            <div className='col-4' onClick={() => setActiveTab('solicitudes')}>
                                <div className={`solicitudes-iniciales-parent ${activeTab === 'solicitudes' ? 'active' : ''}`}>
                                    <b className="solicitudes-iniciales">Solicitudes iniciales</b>
                                    <div className={`wrapper ${activeTab === 'solicitudes' ? 'active' : ''}`}>
                                        <b className="solicitudes-iniciales">{requests.length || 0}</b>
                                    </div>
                                </div>
                            </div>
                            <div className='col-4' onClick={() => setActiveTab('microcredenciales')}>
                                <div className={`proyecto-microcredenciales-parent ${activeTab === 'microcredenciales' ? 'active' : ''}`}>
                                    <b className="proyecto-microcredenciales">Proyecto microcredenciales</b>
                                    <div className={`wrapper_ns ${activeTab === 'microcredenciales' ? 'active' : ''}`}>
                                        <b className="proyecto-microcredenciales">{proposals.length || 0}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>


                            <div className="tab-content">
                                {activeTab === 'solicitudes' ? (
                                    <div className="active">
                                        <div className="solicitudes">

                                            <ul>
                                                <li>
                                                    <button onClick={(e) => handleFilterClick(RequestStatus.Pendiente.id, e)} className="card_solicitud">
                                                        Pendientes <span style={{ paddingLeft: '30px' }}> {pendingCount}</span>
                                                    </button>
                                                </li>
                                                <li><button onClick={(e) => handleFilterClick(RequestStatus.Aprobada.id, e)} className="card_solicitud">
                                                    Aprobadas <span style={{ paddingLeft: '30px' }}>{approvedCount}</span>
                                                </button></li>
                                                <li><button onClick={(e) => handleFilterClick(RequestStatus.Rechazada.id, e)} className="card_solicitud">
                                                    Rechazadas <span style={{ paddingLeft: '30px' }}>{rejectedCount}</span>
                                                </button></li>
                                            </ul>
                                        </div>
                                        {loading ? (
                                            <p>Cargando...</p>
                                        ) : error ? (
                                            <p>Error: {error}</p>
                                        ) : (
                                            <table>
                                                <thead>
                                                    <tr className="table_head">
                                                        {isJuryRole && <th>Nombre solicitante</th>}
                                                        <th>Nombre</th>
                                                        <th>Fecha</th>
                                                        <th>Estado</th>
                                                        <th>Acciones</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredRequests.sort((a, b) => new Date(a.fechaSolicitud) - new Date(b.fechaSolicitud))
                                                        .map(request => (
                                                            <tr key={request.Id}>
                                                                {isJuryRole && <td> {request.usuario.EntityId}</td>}
                                                                <td>{request.nombre}</td>
                                                                <td>{moment(request.fechaSolicitud).format('DD-MM-YYYY')}</td>
                                                                <td>{request.estado.estado}</td>
                                                                <td>
                                                                    {/*request.estado && /*&& request.estado.Id.toUpperCase() === RequestStatus.Pendiente.id && (
                                                                        <div className="dropdown">
                                                                            <a className="btnDropdown btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">

                                                                                <FontAwesomeIcon icon={faEllipsisVertical} />
                                                                            </a>
                                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">

                                                                                <li><a className="dropdown-item" onClick={() => handleRequestDetail(request.Id)}>Ver detalle</a></li>

                                                                            </ul>
                                                                        </div>
                                                                    )*/}
                                                                    {request.estado.Id.toUpperCase() !== RequestStatus.PropuestaIniciada.id &&(
                                                                    <div className="dropdown">
                                                                        <a className="btnDropdown btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                                                            <FontAwesomeIcon icon={faEllipsisVertical} />
                                                                        </a>
                                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                                            {request.usuario.EntityId == userInfo.id && request.estado && request.estado.Id.toUpperCase() === RequestStatus.Aprobada.id &&(
                                                                                <li><a className="dropdown-item" onClick={() => handleLoadApplicationForm(request.Id)}>Iniciar formulario</a></li>
                                                                            )}
                                                                            {request.estado &&(
                                                                                <li><a className="dropdown-item" onClick={() => handleRequestDetail(request.Id)}>Ver detalle</a></li>
                                                                            )}
                                                                        </ul>
                                                                    </div>)}

                                                                </td>

                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                        )}
                                    </div>
                                ) : (
                                    <div className="active">
                                        <div className="solicitudes">

                                            <ul>
                                                <li>
                                                    <button onClick={(e) => handleFilterClick(ProposalStatus.Pendiente.id, e)} className="card_solicitud">
                                                        Pendientes <span style={{ paddingLeft: '30px' }}> {pendingProposalCount}</span>
                                                    </button>
                                                </li>
                                                <li><button onClick={(e) => handleFilterClick(ProposalStatus.Aprobada.id, e)} className="card_solicitud">
                                                    Aprobadas <span style={{ paddingLeft: '30px' }}>{approvedProposalCount}</span>
                                                </button></li>
                                                <li><button onClick={(e) => handleFilterClick(ProposalStatus.Rechazada.id, e)} className="card_solicitud">
                                                    Rechazadas <span style={{ paddingLeft: '30px' }}>{rejectedProposalCount}</span>
                                                </button></li>
                                            </ul>
                                        </div>
                                        {loading ? (
                                            <p>Cargando...</p>
                                        ) : error ? (
                                            <p>Error: {error}</p>
                                        ) : (
                                            <table>
                                                <thead>
                                                    <tr className="table_head">
                                                        {isJuryRole && <th>Nombre solicitante</th>}
                                                        <th>Nombre</th>
                                                        <th>Fecha</th>
                                                        <th>Estado</th>
                                                        <th>Acciones</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredProposals.sort((a, b) => new Date(a.fechaSolicitud) - new Date(b.fechaSolicitud))
                                                        .map(pr => (
                                                            <tr key={pr.Id}>
                                                                {isJuryRole && <td> {pr.id_usuario}</td>}
                                                                <td>{pr.solicitud.nombre}</td>
                                                                <td>{moment(pr.solicitud.fechaSolicitud).format('DD-MM-YYYY')}</td>
                                                                <td>{pr.estado.estado}</td>
                                                                <td>
                                                                    {hasFinalStatus(pr) ? <div></div> : (
                                                                        <div className="dropdown">
                                                                            <a className="btnDropdown btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                <FontAwesomeIcon icon={faEllipsisVertical} />
                                                                            </a>
                                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                                                {pr.id_usuario === userInfo.id && pr.estado && pr.estado.Id.toUpperCase() === ProposalStatus.EnEdicion.id &&
                                                                                    <li><a className="dropdown-item" onClick={() => handleLoadApplicationFormEdition(pr.solicitud.Id, pr.Id)}>Completar formulario</a></li>}
                                                                                {isAdminRole && (<li><a className="dropdown-item" id="modalAsignacion" onClick={() => handleLoadEvaluators(pr)}>Asignar evaluadores</a></li>)}
                                                                                {pr.esJuez && pr.estado && !pr.estado.evaluada && (
                                                                                    <li><a className="dropdown-item" onClick={() => handleLoadApplicationFormJuryEdition(pr.solicitud.Id, pr)}>Evaluar</a></li>
                                                                                )}
                                                                                {pr.esUsuarioResponsable && pr.estado && pr.estado.Id.toUpperCase() === ProposalStatus.EnDictamenFinal.id && (
                                                                                    <li><a className="dropdown-item" onClick={() => handleLoadProposalDetail(pr.solicitud.Id, pr)}>Dar dictamen final</a></li>
                                                                                )}
                                                                            </ul>
                                                                        </div>
                                                                    )}

                                                                </td>

                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                        )}

                                    </div>
                                )}
                            </div>
                        </div>
                    </div>



                </section>
                :

                <section className="container mt-5">
                    <div className='col-12 mb-5'>
                        <div className='row'>
                            <div className="alcance_content">
                                <img src={mcProceso} style={{ width: '100%' }} alt="" />
                            </div>
                        </div>
                    </div>
                </section>
            }
        </>
    );
};

export default Dashboard;
