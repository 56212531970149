import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import 'bootstrap-icons/font/bootstrap-icons.css';
import QuestionnaireJury from './QuestionnaireJury';

import { downloadProposal } from '../actions/proposalActions';

const ApplicationFormJury = ({ navigateToApplicationForm }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { userInfo } = useSelector(state => state.user);
    const { proposalId } = useParams();
    const { proposals } = useSelector(state => state.proposal);
    const [proposal, setProposal] = useState(null);

    const handleBack = () => {
        navigate('/backoffice');
    };
    useEffect(() => {
        const foundProposal = proposals.find(x => x.Id === proposalId);
        setProposal(foundProposal);
    }, [proposalId, proposals]);



    const handleDownloadProposal = () => {
        dispatch(downloadProposal(userInfo, proposalId));
    };
    return (
        <div style={{ marginBottom: '20px' }}>
            <section className="container1">
                <div className='row mb-5'>
                    <div className='col-4'>
                        <a className="btn_back" onClick={handleBack}>
                            Volver al home
                        </a>
                    </div>

                </div>


                <div>
                    <h2>Formulario para evaluación de Microcredenciales</h2>
                    <div className='col-12 container-question' >
                        <div className='row'>
                            <div className='col-11'> Estimado evaluador: <br></br>Le solicitamos que valore la calidad de la propuesta de microcredenciales considerando la totalidad de los indicadores que se incluyen en el formulario.</div>
                        </div>
                        <br></br>
                        <button id="downloadProposal" className="btn btn-primary" onClick={handleDownloadProposal}>Descargar propuesta</button>
                    </div>

                    <QuestionnaireJury />
                </div>

            </section>

        </div>
    );
};

export default ApplicationFormJury;
