
export const getAllQuestions = (user, stageId) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'GET_ALL_QUESTIONS' });

            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}microcredenciales/solicitudes/preguntas?idEtapa=${stageId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
            });
            const requestData = await response.json();

            dispatch({
                type: 'GET_ALL_QUESTIONS_SUCCESS',
                payload: requestData,
            });
        } catch (error) {
            dispatch({
                type: 'GET_ALL_QUESTIONS_ERROR',
                payload: error.message,
            });
        }
    };
};

export const getAllPlanes = (user, careerId) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'GET_ALL_CAREER_PLANS' });

            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}microcredenciales/carreras/planes?carreraId=${careerId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
            });
            const requestData = await response.json();

            dispatch({
                type: 'GET_ALL_CAREER_PLANS_SUCCESS',
                payload: requestData,
            });
        } catch (error) {
            dispatch({
                type: 'GET_ALL_CAREER_PLANS_ERROR',
                payload: error.message,
            });
        }
    };
};

export const getAllAnswers = (user, proposalId) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'GET_ALL_ANSWERS' });

            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}microcredenciales/respuestas/${proposalId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
            });
            const answersData = await response.json();
            const groupedAnswersData = {};
            
            answersData.forEach((answer) => {
                const questionId = answer.preguntaAsociada.Id;
                let isMulti = answer.preguntaAsociada.idTipo === '226f383e-d1ae-40b2-964f-eb41585d1cff' ? true : false;
                
                if (isMulti) {
                    if (groupedAnswersData[questionId]) {
                        if (groupedAnswersData[questionId].multipleAnswers) {
                            groupedAnswersData[questionId].multipleAnswers.push(answer);
                        } else {
                            groupedAnswersData[questionId].multipleAnswers = [answer];
                        }
                    } else {
                        groupedAnswersData[questionId] = { multipleAnswers: [answer] };
                    }
                } else {
                    groupedAnswersData[questionId] = { ...answer };
                }
            });

            //console.log('groupedAnswersData: ',groupedAnswersData)
            // Usar el nombre de variable corregido
            Object.entries(groupedAnswersData).forEach(([key, value]) => {
                
                if(value.multipleAnswers && value.multipleAnswers.length > 0){

                    let subjEstructured = value.multipleAnswers.map(a => ({
                        questionId: key,
                        answerId: a.Id,
                        answer: a.respuesta,
                        //fileName: a.nombreArchivo,
                        //filePath: a.rutaArchivo,
                        observacion: a.observacion,
                        multipleAnswers:undefined
                    }))

                    dispatch({
                        type: 'SET_ANSWER',
                        payload: { 
                            questionId: key,
                            multipleAnswers: subjEstructured
                        },
                    });

                    value.multipleAnswers.forEach((aa)=>{

                        if(aa.respuestasMultiples && aa.respuestasMultiples.length > 0){

                            aa.respuestasMultiples.forEach( (aaa) => {
                                
                                dispatch({
                                    type: 'SET_ANSWER_MULTIPLE',
                                    payload: { 
                                        grandQuestionIdM: aa.preguntaAsociada.Id,
                                        grandQuestionAnswer: aa.respuesta,
                                        answerIdM: aaa.Id,
                                        questionIdM: aaa.pregunta.Id,
                                        answerM: aaa.respuesta,
                                        //observacionM: aaa.observacion,
                                    },
                                });
                            })
                        }
                        
                    })

                }else{

                    dispatch({
                        type: 'SET_ANSWER',
                        payload: { 
                            answerId: value.Id,
                            questionId: key,
                            answer: value.respuesta,
                            fileName: value.nombreArchivo,
                            filePath: value.rutaArchivo,
                            observacion: value.observacion,
                        },
                    });
                }
            });
           
        } catch (error) {
            dispatch({
                type: 'GET_ALL_ANSWERS_ERROR',
                payload: error.message,
            });
        }
    };
};


export const getAllJuryAnswers = (user, proposalId) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'GET_ALL_JURY_ANSWERS' });

            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}microcredenciales/respuestas/evaluadores/${proposalId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
            });
            const answersData = await response.json();
            dispatch({
                type: 'GET_ALL_JURY_ANSWERS_SUCCESS',
                payload: answersData,
            });
           
        } catch (error) {
            dispatch({
                type: 'GET_ALL_JURY_ANSWERS_ERROR',
                payload: error.message,
            });
        }
    };
};


export const setAnswer = (questionId, answer, observacion, multipleAnswers ) => ({
    type: 'SET_ANSWER',
    payload: { questionId, answer, observacion, multipleAnswers },
  });

export const setAnswerMultiple = (grandQuestionIdM,grandQuestionAnswer, questionIdM, answerM, observacionM ) => ({
    type: 'SET_ANSWER_MULTIPLE',
    payload: { grandQuestionIdM, grandQuestionAnswer,questionIdM, answerM, observacionM },
  });

export const saveAnswer = (answerData, user, file) => async (dispatch) => {
    try {
        dispatch({ type: 'SAVE_ANSWER' });
        const formData = new FormData();
        
        Object.keys(answerData).forEach((key) => {
          formData.append(key, answerData[key]);
        });
        
        formData.append('file', file);
        formData.append('model', JSON.stringify(answerData));
        
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}microcredenciales/respuestas/save`, {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + user.token
          },
          body: formData
        });

        if (response.ok) {           
            dispatch({ type: 'SAVE_ANSWER_SUCCESS', payload: answerData });
        
        } else {
            const errorData = await response.statusText;           
            dispatch({ type: 'SAVE_ANSWER_FAIL', payload: errorData });
        }
        
    } catch (error) {
        dispatch({ type: 'SAVE_ANSWER_ERROR', payload: error.message });
    }
};

export const deleteAnswer = (idAnswer, user) => async (dispatch) => {
    try {
        dispatch({ type: 'DELETE_ANSWER' });
        
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}microcredenciales/respuestas/${idAnswer}`, {
          method: 'DELETE',
          headers: {
            'Authorization': 'Bearer ' + user.token
          },
          //body: formData
        });

        if (response.ok) {           
            dispatch({ type: 'DELETE_ANSWER_SUCCESS', payload: idAnswer });
        
        } else {
            const errorData = await response.statusText;           
            dispatch({ type: 'DELETE_ANSWER_FAIL', payload: errorData });
        }
        
    } catch (error) {
        dispatch({ type: 'DELETE_ANSWER_ERROR', payload: error.message });
    }
};