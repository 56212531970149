export const fetchMicrocredentialTypes = (user) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'FETCH_MICROCREDENTIAL_TYPES_REQUEST' });

            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}microcredenciales/solicitudes/tipos`, {
                    method: 'GET',        
                    headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+ user.token
                    },
            });

            if(response.ok){
                console.log(response.status)
            }
            const requestData = await response.json();           
          
            dispatch({
                type: 'FETCH_MICROCREDENTIAL_TYPES_SUCCESS',
                payload: requestData,
            });
        } catch (error) {
            dispatch({
                type: 'FETCH_MICROCREDENTIAL_TYPES_ERROR',
                payload: error.message,
            });
        }
    };
};