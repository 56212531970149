import React, { useState } from 'react';
import Texto from './Texto';
import { useDispatch, useSelector } from 'react-redux';
import { setAnswer } from '../../actions/questionActions';

const YesPreguntaNo = ({ title, additionalQuestions, idPregunta }) => {
    //const [radioValue, setRadioValue] = useState(null);
    const dispatch = useDispatch();
    const answers = useSelector(state => state.question.answers);
    const answerValue = answers[idPregunta]?.answer || '';

    const handleRadioChange = (event) => {
        const newValue = event.target.value;
        //setRadioValue(newValue);
        dispatch(setAnswer(idPregunta, newValue));
    };

    /*const handleTextChange = (id, textValue) => {       
        dispatch(setAnswer(id, textValue));
    };*/

    return (
        <div className="form-check">
            <label className='question-title'>{title}</label>
            <div style={{marginTop:'2%'}}>
                <input
                    type="radio"
                    id="si"
                    className='form-check-input'
                    name="titulo1"
                    value="1"
                    checked={answerValue === "1"}
                    onChange={handleRadioChange}
                />
                <label style={{marginBottom:'1%'}} className="form-check-label" htmlFor="si">Si</label>
            </div>
            <div>
                <input
                    type="radio"
                    id="no"
                    className='form-check-input'
                    name="titulo2"
                    value="0"
                    checked={answerValue === "0"}
                    onChange={handleRadioChange}
                />
                <label style={{marginBottom:'1%'}} className="form-check-label" htmlFor="no">No</label>
            </div>
                
            
             {answerValue === "1" && (
                <div className="additional-questions">
                    {additionalQuestions && additionalQuestions.map(pregunta => (
                       
                           <Texto
                                        key={pregunta.Id}
                                        title={pregunta.titulo}
                                        idPregunta={pregunta.Id}                                       
                                    />
                        
                    ))}
                </div>
            )}
        </div>
    );
};

export default YesPreguntaNo;