import { RequestStatus } from "../enums/requestStatus";
import {notifySuccess,notifyError}  from "../components/Toast";

const simulateApiPost = (data) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({ status: 200, message: "Success", data, ok: true });
      //reject({ status: 500, statusText: "Internal Server Error", ok:false });
    }, 2000);
  });
};

export const getFileRequest = (fileName, user) => async (dispatch) => {
  try {
    dispatch({ type: 'GET_FILE_REQUEST' });

    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}microcredenciales/respuestas/Solicitudes-Contenidos-${fileName.substring(0, 4)}/getFile/${fileName}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.token
      },
    });
    

    if (response.ok) {
      const blob = await response.blob();
      dispatch({ type: 'GET_FILE_REQUEST_SUCCESS' });
       
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(downloadUrl);
    } else {
      const errorData = await response.statusText;
      dispatch({ type: 'GET_FILE_REQUEST_FAIL', payload: errorData });
    }

  }
  catch (error){
    dispatch({ type: 'GET_FILE_REQUEST_ERROR', payload: error.message });
  }
};


export const createRequest = (requestData, file, user, navigate, isJury) => async (dispatch) => {
  try {
    dispatch({ type: 'CREATE_REQUEST_REQUEST' });

    const formData = new FormData();

    Object.keys(requestData).forEach((key) => {
      formData.append(key, requestData[key]);
    });

    formData.append('file', file);
    formData.append('model', JSON.stringify(requestData));

    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}microcredenciales/solicitudes/save`, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + user.token
      },
      body: formData
    });

    /*const response = await simulateApiPost(requestData);*/
   
    if (response.ok) {
      dispatch({ type: 'CREATE_REQUEST_SUCCESS', payload: requestData });
      if(isJury){
        if(requestData.estado.Id == RequestStatus.Aprobada.id){
          navigate('/backoffice/request-detail-sent');
        }else{
          navigate('/backoffice/request-detail-fail');
        }
       
      }else {
        navigate('/backoffice/request-sent');
      }
      
    } else {
      const errorData = await response.json();
      notifyError(`Error: ${errorData.Message}`);
      dispatch({ type: 'CREATE_REQUEST_FAIL', payload: errorData.Message });
    }
  } catch (error) {
    console.log(error)
    notifyError(error.message)
    dispatch({ type: 'CREATE_REQUEST_ERROR', payload: error.message });
  }
};

export const getAllRequests = (user) => {
  return async (dispatch) => {
    try {
      dispatch({ type: 'GET_ALL_REQUESTS' });

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}microcredenciales/solicitudes?fechaDesde&fechaHasta&tipoMicro&estado`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + user.token
        },
      });
      const requestData = await response.json();
      
      dispatch({
        type: 'GET_ALL_REQUESTS_SUCCESS',
        payload: requestData,
      });
    } catch (error) {
      dispatch({
        type: 'GET_ALL_REQUESTS_ERROR',
        payload: error.message,
      });
    }
  };
};


// Update an existing request
export const updateRequest = (requestId, updatedData) => ({
  type: 'UPDATE_REQUEST',
  payload: { requestId, updatedData },
});

// Delete a request
export const deleteRequest = (requestId) => ({
  type: 'DELETE_REQUEST',
  payload: requestId,
});
