import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import mcProceso from '../assets/mcProceso.png';

const RequestSent = () => {
    const navigate = useNavigate();
    const handleBack = () => {
        navigate('/backoffice'); // This will go back one step in history
    };
    return (
        <section className="container1">
            <a className="btn_back" onClick={handleBack}>
                Volver al home
            </a>
            <section>
                
                <div className="sol_sent">
                    <h2>Solicitud enviada con éxito</h2>
                    <p>Vamos a evaluar tu proyecto desde nuestra perspectiva institucional y determinar la relevancia de las habilidades y conocimientos que promueve, asegurándonos de que estén alineados con las demandas del mercado.<br /><br /> ¡Estamos emocionados de ver lo que tienes en mente! Una vez que tu solicitud sea aprobada, recibirás todas las indicaciones para presentar tu proyecto.<br /><br />¡Gracias por tu interés!</p>
                    <Link to="/backoffice/new-request" >
                        <div className="d-flex align-items-center">
                            <Button variant="primary" className="btn">
                                Enviar nueva solicitud
                            </Button>                                    
                        </div>
                    </Link>    
                </div>
                <div style={{marginTop:'2%', marginBottom:'5%'}}>
                    <img src={mcProceso} style={{width: '100%'}} alt="" /> 
                </div>
            </section>
        </section>
    );
};

export default RequestSent;
