const initialState = {
    questions: [],
    plans: [],
    answers: {},
    juryAnswers: [],
    loading: false,
    error: null,
};

const questionReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CREATE_QUESTION':
            return {
                ...state,
                loading: true,
                error: null,
            };
        case 'CREATE_QUESTION_SUCCESS':
            return {
                ...state,
                loading: false,
                error: null,
            };
        case 'CREATE_QUESTION_FAIL':
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case 'GET_ALL_QUESTIONS':
            return {
                ...state,
                loading: true,
                error: null,
            };
        case 'GET_ALL_QUESTIONS_SUCCESS':
            return {
                ...state,
                questions: action.payload,
                loading: false,
                error: null,
            };
        case 'GET_ALL_QUESTIONS_ERROR':
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case 'GET_ALL_CAREER_PLANS':
            return {
                ...state,
                loading: true,
                error: null,
            };
        case 'GET_ALL_CAREER_PLANS_SUCCESS':
            return {
                ...state,
                plans: action.payload,
                loading: false,
                error: null,
            };
        case 'GET_ALL_CAREER_PLANS_ERROR':
            return {
                ...state, 
                loading: false,
                error: action.payload,
            };
        case 'GET_ALL_JURY_ANSWERS':
        case 'GET_ALL_ANSWERS':
            return {
                ...state,
                answers: {},
                juryAnswers: [],
                loading: true,
                error: null,
            };
        case 'GET_ALL_ANSWERS_SUCCESS':
            return {
                ...state,
                answers: action.payload,
                loading: false,
                error: null,
            };
        case 'GET_ALL_JURY_ANSWERS_ERROR':
        case 'GET_ALL_ANSWERS_ERROR':
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case 'SET_ANSWER':
            /*return {
              ...state,
              answers: {
                ...state.answers,
                [action.payload.questionId]: action.payload.answer,
              },
            };    */
            const { answerId,questionId, answer, observacion, fileName, filePath, multipleAnswers } = action.payload;

            const updatedAnswer = observacion ? {answerId ,answer, observacion, fileName, filePath} : { answerId,answer, fileName, filePath };
            updatedAnswer.multipleAnswers = multipleAnswers? multipleAnswers : undefined;
            
            return {
                ...state,
                answers: {
                    ...state.answers,
                    [questionId]: updatedAnswer,
                }
            };
        case 'SET_ANSWER_MULTIPLE':
            const { grandQuestionIdM,grandQuestionAnswer, questionIdM,answerIdM, answerM, observacionM } = action.payload;
            const updatedAnswerM = observacionM? {answerId:answerIdM,answer:answerM,observacion:observacionM} : {answerId:answerIdM,answer:answerM} 
            const actualGrandAnswer = state.answers[grandQuestionIdM] || {};

            if(actualGrandAnswer){
                const index = actualGrandAnswer.multipleAnswers.findIndex(
                    a=> a.questionId === grandQuestionIdM && a.answer === grandQuestionAnswer
                )
                if(index !== -1){
                    actualGrandAnswer.multipleAnswers[index].multipleAnswers = {
                        ...actualGrandAnswer.multipleAnswers[index].multipleAnswers,
                        [questionIdM] : updatedAnswerM
                    }
                }
            }

            return {
                ...state,
                answers: {
                    ...state.answers,
                    [grandQuestionIdM]: actualGrandAnswer,
                }
            };
        case 'GET_ALL_JURY_ANSWERS_SUCCESS':
            return {
                ...state,
                juryAnswers: action.payload,
                loading: false,
                error: null,
            };
        default:
            return state;
    }
};

export default questionReducer;
