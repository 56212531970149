import React, { useState, useCallback } from 'react';
import { Navbar, Nav, Button } from 'react-bootstrap';
import LoginModal from './LoginModal';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png'; 

const NavigationBar = () => {
    
    const [modalShow, setModalShow] = useState(false);

    const handleHide = useCallback(() => {       
        setModalShow(false)
    }, []);
   
    return (
        <>
        <header className="landing_nav">
            <Navbar expand="lg" style={{width: '100%'}}>
                <Navbar.Brand as={Link} to="/">
                    <div className="logo">
                        <img src={logo} alt="UAI microcredenciales" />
                    </div>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                   
                        <Nav>
                            <Button  style={{ marginRight: '10px' }} onClick={() => setModalShow(true)}>Iniciar sesión</Button>
                        </Nav>
                    
                    {/* {userInfo && (
                        <Nav>
                            <Nav.Link as={Link} to="/backoffice/dashboard">Dashboard</Nav.Link>
                            <Dropdown>
                                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                    Mi Cuenta
                                </Dropdown.Toggle>
                                <Dropdown.Menu>                                    
                                    <Dropdown.Item as={Link} to="/backoffice/account">Mi Cuenta</Dropdown.Item>
                                    <Dropdown.Item onClick={handleLogout}>Cerrar sesión</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Nav>
                    )} */}
                </Navbar.Collapse>
            </Navbar>
            <LoginModal show={modalShow} onHide={handleHide} />
            </header>
        </>
    );
};

export default NavigationBar;
