import React from 'react';
import Texto from './Texto';
import TextoArchivo from './TextoArchivo';
import Archivo from './Archivo';
import YesPreguntaNo from './YesPreguntaNo';
import RadioTexto from './RadioTexto';
import Fecha from './Fecha';
import YesNo from './YesNo';
const Padre = ({ additionalQuestions }) => {
    
    return (
        <div className='col-12'>
            <div className='row'>
                {additionalQuestions.map(pregunta => (
                   
                    <div key={pregunta.Id}>                       
                        {(() => {                            

                            switch (pregunta.tipo) {
                                case 'Texto':
                                    return (
                                        <Texto
                                            title={pregunta.titulo}
                                            idPregunta={pregunta.Id}
                                        />
                                    );
                               
                                case 'Archivo':
                                    return (
                                        <Archivo
                                            title={pregunta.titulo}
                                            idPregunta={pregunta.Id}
                                        />
                                    );
                                case 'Fecha':
                                    return (                                        
                                        <Fecha
                                            title={pregunta.titulo}                                            
                                            idPregunta={pregunta.Id}                                            
                                        />                                        
                                    );
                                case 'YesPreguntaNo':
                                    return (
                                        <YesPreguntaNo
                                            title={pregunta.titulo}
                                            additionalQuestions={pregunta.subpreguntas}
                                            idPregunta={pregunta.Id}
                                        />
                                    );
                                    case 'YesNo':
                                        return (<YesNo title={pregunta.titulo} idPregunta={pregunta.Id} observacion={pregunta.tiene_observacion} />);
                                case 'RadioTexto':
                                case 'Radio':
                                    return (
                                        <RadioTexto
                                            title={pregunta.titulo}
                                            options={pregunta.subpreguntas}
                                            idPregunta={pregunta.Id}
                                        />
                                    );
                                default:
                                    return null;
                            }
                        })()}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Padre;
