const initialState = {
    userInfo: null,
    loading: false,
    error: null,
    externalUser: null
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'USER_LOGIN_REQUEST':
            return { ...state, loading: true, error: null };
        case 'USER_LOGIN_SUCCESS':
            return { ...state, loading: false, userInfo: action.payload };
        case 'USER_LOGIN_FAIL':
            return { ...state, loading: false, error: action.payload };
        case 'CREATE_EXTERNAL_USER':
            return { ...state, loading: true, error: null };
        case 'CREATE_EXTERNAL_USER_SUCCESS':
            return { ...state, loading: false, externalUser: action.payload };
        case 'CREATE_EXTERNAL_USER_FAIL':
            return { ...state, loading: false, error: action.payload };
        case 'CREATE_EXTERNAL_USER_ERROR':
            return { ...state, loading: false, error: action.payload };
        case 'USER_LOGOUT':
            return {
                ...state,
                userInfo: null,
            };
        default:
            return state;
    }
};

export default userReducer;
