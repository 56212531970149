import React from 'react';
import { Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setAnswer } from '../../actions/questionActions';

const Texto = ({ title, idPregunta }) => {
  const dispatch = useDispatch();
  const answers = useSelector(state => state.question.answers);
  const answerValue = answers[idPregunta]?.answer || '';
  
  const handleTextChange = (textValue) => {
    dispatch(setAnswer(idPregunta, textValue));
  };
  return (
    <div>

      <Form.Group className="tipo-de-credencial-parent mb-3">
        <Form.Label className='nombre'>{title}</Form.Label>
        <Form.Control as="textarea" rows={3} placeholder="Ingresa tu respuesta" required value={answerValue} onChange={(e) => handleTextChange(e.target.value)} />
      </Form.Group>


    </div>
  );
};

export default Texto;
