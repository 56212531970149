import React, { useState, useEffect } from 'react';
import { Form, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useNavigate } from 'react-router-dom';
import Questionnaire from './Questionnaire';
import icono1 from '../assets/icono1.png';
import icono2 from '../assets/icono2.png';
import icono3 from '../assets/icono3.png';
import icono4 from '../assets/icono4.png';

const ApplicationForm = ({ navigateToApplicationForm }) => {

    const navigate = useNavigate();
    const handleBack = () => {
        navigate('/backoffice');
    };

    return (
        <div style={{ marginBottom: '20px' }}>
            <section className="container1">
                <div className='row mb-5'>
                    <div className='col-4'>
                    <a className="btn_back" onClick={handleBack}>
                        Volver al home
                    </a>
                    </div>
                   
                </div>
                <section >
                <h2>Plantilla de Diseño de Microcredenciales</h2>
                    <div className='col-12 container-question' >
                        <div className='row mb-3'>
                            <div className='col-12'>
                            
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-1' style={{width:'40px'}}><img src={icono1} alt="" /></div>
                            <div className='col-11'>Si su solicitud inicial ha sido autorizada le solicitamos que complete los campos del formulario para presentar su propuesta de microcredencial. Se requiere que todos los componentes de la microcredencial estén desarrollados.</div>
                            
                        </div>
                        <div className='row mb-3'>
                            <div className='col-1' style={{width:'40px'}}><img src={icono2} alt="" /></div>
                            <div className='col-11'>Dispone de un plazo de 30 días para el completamiento de la presentación.</div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-1' style={{width:'40px'}}><img src={icono3} alt="" /></div>
                            <div className='col-11'>El proyecto será evaluado por un Comité de expertos, quienes emitirán un dictamen en un plazo máximo de 15 días hábiles. En caso de haber obtenido un resultado favorable la MC propuesta ingresará al catálogo y comenzará el proceso de diseño y difusión. </div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-1' style={{width:'40px'}}><img src={icono4} alt="" /></div>
                            <div className='col-11'>En caso de no cumplirse los plazos establecidos, caducará la presente solicitud.</div>
                        </div>
                    </div>
                </section>
                <Questionnaire />
            </section>

        </div>
    );
};

export default ApplicationForm;
