import React, { useState, useCallback } from 'react';
import heroImage from '../assets/Hero-landing1.png';
import bubblesImage from '../assets/bubbles.png';
import thumbsUpImage from '../assets/Thumbsup.png';
import peopleImage from '../assets/People.png';
import huellaImage from '../assets/huella.png';
import direccionImage from '../assets/direccion.png';
import EstudiantesImage from '../assets/EstudiantesNEW.png';
import PersonasImage from '../assets/PersonasNEW.png';
import TrabajadoresEmprendedoresImage from '../assets/TrabajadoresEmprendedoresNEW.png';
import personasSituacionDesempleoImage from '../assets/PersonasSituacionDesempleoNEW.png';
import PersonasTrabajando from '../assets/PersonasTrabajando2.png'
import tiposCredencialesImage from '../assets/tipos_credenciales.png';
import arrowRightImage from '../assets/arrow-small-right.png';
import NavigationBar from './NavigationBar';
import LoginModal from './LoginModal';
import { Button } from 'react-bootstrap';
import { width } from '@fortawesome/free-regular-svg-icons/faAddressBook';

const Home = () => {
    const [modalShow, setModalShow] = useState(false);

    const handleHide = useCallback(() => {
        // logic to handle the hide
        setModalShow(false)
    }, []);

    return (
        <div>

            <NavigationBar />

            <main>
                <section className="container">
                    <div className="hero">
                        <img src={heroImage} alt="" />
                        <div className="hero-content">
                            <h1>Microcredenciales<br></br><span style={{ color: '#F20A61' }}>MacroImpacto</span></h1>
                            <p>Las microcredenciales son certificaciones que reconocen el logro de  aprendizajes obtenidos en programas cortos de formación y que responden a necesidades sociales, personales, culturales o del mercado laboral.​</p>
                            <p>Su sistema de evaluación es robusto, basado en criterios y estándares de calidad claramente definidos.</p>
                            <p>Las evidencias de logro que acompañan a las microcredenciales garantizan su transparencia y confiabilidad.</p>
                            <div className="caption">
                                <p href="#">Comenzá ahora</p>
                                <img src={arrowRightImage} alt="" />                                
                                <Button  style={{ marginRight: '10px' }} onClick={() => setModalShow(true)}>Iniciar sesión</Button>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="container">
                    <div className="huella">
                        <img src={tiposCredencialesImage} alt="" />
                        <p> <span style={{ color: '#F20A61' }}>TIPOS DE CREDENCIALES</span> QUE PODÉS DESARROLLAR</p>
                    </div>
                    <div className="credenciales">
                        <div className="detalle_credencial">
                            <div className="numero_credenciales">01</div>
                            <div className="benefit_content">
                 
                                <h3>Microcredencial para el desarrollo profesional</h3>
                               
                                <p>Se orientan a habilidades y competencias técnicas y/o transversales alineadas a demandas y necesidades específicas de <span style={{ backgroundColor: '#F20A61', color: '#fff' }}>los distintos sectores de la actividad económica.</span></p>
                            </div>
                        </div>
                        <div className="detalle_credencial">
                            <div className="numero_credenciales">02</div>
                            <div className="benefit_content">
                           
                                <h3>Microcredencial para el desarrollo personal/social</h3>
                                
                                <p>Se orientan a fomentar <span style={{ backgroundColor: '#F20A61', color: '#fff' }}>estilos de vida saludables y sostenibles</span> mediante la promoción de  <span style={{ backgroundColor: '#F20A61', color: '#fff' }}>habilidades personales y sociales.</span>​</p>
                            </div>
                        </div>
                        <div className="detalle_credencial">
                            <div className="numero_credenciales">03</div>
                            <div className="benefit_content">
                     
                                <h3>Microcredenciales para el reconocimiento de aprendizajes previos</h3>
                               
                                <p>Se orientan a reconocer habilidades o competencias adquiridas <span style={{ backgroundColor: '#F20A61', color: '#fff' }}>en ámbitos formales y no formales.</span></p>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="direccion">
                            <h1>Microcredenciales <br></br>Grandes oportunidades<br></br><span style={{ color: '#F20A61' }}>¿A quiénes va dirigido?</span></h1>
                            <img src={direccionImage} alt="" />
                        </div>
                        <div className="tarjetas">
                            <div className="tarjeta">
                                <img src={EstudiantesImage} alt="" />
                                <p style={{ textAlign: 'center' }}><strong>Estudiantes</strong> <hr></hr><span className='Tarjeta-Text'>Que busquen potenciar y visibilizar sus competencias profesionales.</span></p>
                            </div>
                            <div className="tarjeta space">
                                <img style={{  }}width="200" height="190" src={PersonasTrabajando} alt="" />
                                <p style={{ textAlign: 'center' }}><strong>Trabajadores y emprendedores</strong> <hr></hr><span className='Tarjeta-Text'>Que quieran actualizar y perfeccionar sus competencias y habilidades para mejorar su empleabilidad.</span></p>
                            </div>
                            <div className="tarjeta">
                                <img src={personasSituacionDesempleoImage} alt="" />
                                <p style={{ textAlign: 'center' }}><strong>Personas</strong> <hr></hr><span className='Tarjeta-Text'>Interesadas en potenciar su desarrollo personal, social o mejorar su empleabilidad en cualquier etapa de la vida.</span></p>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="container">
                        <div className="direccion">
                            <h1 id='Texteh1'>Una oportunidad para desarrollar, mejorar, certificar y visibilizar habilidades y competencias<br></br><span style={{ color: '#F20A61' }}>alcances de las microcredenciales</span></h1>
                            <div className="alcance_content_father">
                                <div className="alcance_content">
                                    <img src={arrowRightImage} alt="" />
                                    <p>Certificar y validar el logro de resultados de aprendizaje luego de un proceso formativo corto de educación no formal.</p>
                                </div>
                                <div className="alcance_content">
                                    <img src={arrowRightImage} alt="" />
                                    <p>Certificar y validar resultados de aprendizaje previstos en asignaturas de carreras formales.</p>
                                </div>
                                <div className="alcance_content">
                                    <img src={arrowRightImage} alt="" />
                                    <p>Reconocer créditos académicos conforme a las previsiones del “Sistema de Reconocimiento de Créditos Académicos” de la UAI.</p>
                                </div>
                                <p className="aclaracion">*Las microcredenciales pueden ser acoplables y acumular créditos académicos para una carrera, siempre que el plan de estudios contemple la transferencia de los créditos acumulados por el interesado.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="container">
                    <div className="huella">
                        <img src={huellaImage} alt="" />
                        <p>Sumate a las nuevas tendencias en educación<span style={{ color: '#F20A61' }}></span></p>
                    </div>
                </section>
            </main>
            <LoginModal show={modalShow} onHide={handleHide} />
        </div>
    );
};

export default Home;
