import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const RequestDetailFail = () => {
    const navigate = useNavigate();
    const handleBack = () => {
        navigate('/backoffice'); // This will go back one step in history
    };
    return (
        <section className="container1">
            <a className="btn_back" onClick={handleBack}>
                Volver al home
            </a>
            <section>
                <div className="sol_sent">
                    <h2>Solicitud rechazada con éxito</h2>
                    <p>La solicitud fue rechazada correctamente y le enviaremos la notificación correspondiente al usuario</p>
                    
                </div>
            </section>
        </section>
    );
};

export default RequestDetailFail;
