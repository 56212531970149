import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavigationBar from './components/NavigationBar';
import Footer from './components/Footer';
import Home from './components/Home';
import UserBackoffice from './components/UserBackoffice';
import ProtectedRoute from './components/ProtectedRoute';
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from "./store";
import RequestForm from './components/RequestForm';
import Dashboard from './components/Dashboard';
import ApplicationForm from './components/ApplicationForm';
import ApplicationFormJury from './components/ApplicationFormJury';
import NavigationBarBackoffice from './components/NavigationBarBackoffice';
import RequestSent from './components/RequestSent';
import RequestDetail from './components/RequestDetail';
import RequestDetailFail from './components/RequestDetailFail';
import RequestDetailSent from './components/RequestDetailSent';
import { Toast } from './components/Toast';
import ProposalDetail from './components/ProposalDetail';

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router basename="/">
          <Toast />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/backoffice/*" element={<NavigationBarBackoffice />} />
          </Routes>
          <div className="container-fluid">
            <Routes>
              <Route path="/backoffice" element={
                <ProtectedRoute>
                  <UserBackoffice />
                </ProtectedRoute>
              } />
              <Route path="/backoffice/new-request" element={
                <ProtectedRoute>
                  <RequestForm />
                </ProtectedRoute>
              } />
              <Route path="/backoffice/dashboard" element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              } />
              <Route path="/backoffice/request-sent" element={
                <ProtectedRoute>
                  <RequestSent />
                </ProtectedRoute>
              } />
              <Route path="/backoffice/applicationform/:proposalId" element={
                <ProtectedRoute>
                  <ApplicationForm />
                </ProtectedRoute>
              } />
              <Route path="/backoffice/applicationformJury/:proposalId" element={
                <ProtectedRoute>
                  <ApplicationFormJury />
                </ProtectedRoute>
              } />
              <Route path="/backoffice/proposal-detail/:proposalId" element={
                <ProtectedRoute>
                  <ProposalDetail />
                </ProtectedRoute>
              } />
              <Route path="/backoffice/request-detail/:requestId" element={
                <ProtectedRoute>
                  <RequestDetail />
                </ProtectedRoute>
              } />
              <Route path="/backoffice/request-detail-sent" element={
                <ProtectedRoute>
                  <RequestDetailSent />
                </ProtectedRoute>
              } />
              <Route path="/backoffice/request-detail-fail" element={
                <ProtectedRoute>
                  <RequestDetailFail />
                </ProtectedRoute>
              } />
            </Routes>
          </div>
          <Footer />
        </Router>
      </PersistGate>
    </Provider>
  );
};

export default App;
